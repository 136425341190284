import React, { Component } from 'react'

import api from '../../services';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';

toast.configure();

export default class NotificacaoForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',

            cd_notificacao: '', 
            ds_notificacao_titulo: '', 
            ds_notificacao_descricao: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        
        const target = event.target;
        const name = target.name;
        var value2 = target.type === 'checkbox' ? target.checked : target.value;
        
        this.setState({
            [name]: value2
        });
    }

    componentWillMount(){

        const par = this.props.match.params;
        
        if (par.id !== undefined) {
            this.setState({
                cd_notificacao: par.id
            }, () => {
                this.consultaAPI();
            });
        } 
    }

    consultaAPI = async() => {
        
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
    
        try { 

            this.setState({ isLoading: true });

            
            var response = await api.get(`/notificacao/${this.state.cd_notificacao}`);

            let dados = response.data.data || '';
                    
            this.setState({ 
                ds_notificacao_titulo: dados.ds_notificacao_titulo || '',  
                ds_notificacao_descricao: dados.ds_notificacao_descricao || '',
            }, () => {
                console.log('state ',this.state)
            });
            
        
        } catch (response) { 
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    validation = () => {
        const { ds_notificacao_descricao, ds_notificacao_titulo } = this.state;
        
        if(ds_notificacao_descricao === '' || ds_notificacao_titulo === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ 
            submittedForm: true, 
        });
        
        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
        
        try { 
            console.log(this.state)
            if( this.state.cd_notificacao != '' ){
                var response = await api.put(`/notificacao/${this.state.cd_notificacao}`,this.state);
            } else {
                var response = await api.post(`/notificacao/`,this.state);
            }

            console.log(response)
            
            if( response.data.errors === "" ){    
                if (this.state.cd_notificacao != '') {
                    toast.success('Notificação editada com sucesso!', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Notificação realizada com sucesso!', { autoClose: 3000, toastId: 1 });
                }

                this.voltar();

            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }
    
    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    voltar = () => {
        this.props.history.push('/notificacao');
    }

    render(){
        const { submittedForm, isLoading } = this.state;
        
        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>{ this.state.cd_notificacao === '' ? 'Nova Notificação' : 'Notificação'}</h2>
                                        </div>
                                        
                                        <div className="float-right">
                                            <Link to={`/notificacao/form/`} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Nova Notificação
                                            </Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">

                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_notificacao_titulo">Título:</label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_notificacao_titulo" 
                                                        className="form-control" 
                                                        value={this.state.ds_notificacao_titulo}
                                                        onChange={this.handleInputChange} 
                                                    />

                                                    { submittedForm && this.state.ds_notificacao_titulo === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_notificacao_descricao">Mensagem:</label>
                                                    <textarea 
                                                        name="ds_notificacao_descricao" 
                                                        style={{ height: 120 }}
                                                        className="form-control" 
                                                        value={this.state.ds_notificacao_descricao}
                                                        onChange={this.handleInputChange} 
                                                    />

                                                    { submittedForm && this.state.ds_notificacao_descricao === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}