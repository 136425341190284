import React, { Component } from 'react'

import api from '../../services';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';
import { Button } from 'reactstrap';

toast.configure();

export default class ProvaForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            submittedForm: false,
            tooltip2: '',

            cd_prova: '',
            ds_prova: '',
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
        });
    }

    componentDidMount() {
        const params = this.props.match.params;

        if (params.id !== undefined) {
            this.setState({
                cd_prova: params.id
            }, () => {
                this.consultaAPI();
            });
        } 
        this.consultaAPI();
    }

    consultaAPI = async () => {
        try {
            if (this.state.cd_prova === '') {
                return;
            }
            
            this.setState({ isLoading: true });

            const response = await api.get(`/prova/${this.state.cd_prova}`);
            const dados = response.data.data;

            this.setState({
                isLoading: false,
                cd_prova: dados.cd_prova,
                ds_prova: dados.ds_prova,
            });
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({
            submittedForm: true,
        });

        try {
            const response = this.state.cd_prova != ''
                ? await api.put(`/prova/${this.state.cd_prova}`, this.state)
                : await api.post(`/prova/`, this.state);

            if (response.data.errors === "") {
                toast.success(
                    `Prova ${this.state.cd_prova != '' ? 'editada' : 'inserida'} com sucesso!`,
                    { autoClose: 3000, toastId: 1 }
                );

                this.voltar();
            } else {
                toast.error(response.data.errors, { autoClose: 3000, toastId: 1 });
            }
        } catch (resp) {
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    voltar = () => {
        this.props.history.push('/prova');
    }

    changeNovaProva = () => {
        this.setState({
            cd_prova: '',
            ds_prova: '',
        });

        this.props.history.push(`/prova/form/${this.state.cd_prova}`)
    }

    render() {
        const { submittedForm, isLoading } = this.state;

        return (
            <div style={{ width: '100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>{this.state.cd_prova === '' ? 'Nova Prova' : 'Prova'}</h2>
                                        </div>

                                        <div className="float-right">
                                            <Button onClick={() => this.changeNovaProva()} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Nova Prova
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_prova">Nome:</label>
                                                    <input
                                                        type="text"
                                                        name="ds_prova"
                                                        className="form-control"
                                                        value={this.state.ds_prova}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {submittedForm && this.state.ds_prova === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                    Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}