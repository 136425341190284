import React, { Component } from 'react'

import api from '../../services';
import Pagination from "react-pagination-js";

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody, PopoverHeader, } from 'reactstrap';
import FileBase64 from 'react-file-base64';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Tabs from '../../components/Tabs';
import Preview from '../../components/Preview';
toast.configure();


export default class Crianca extends Component{
    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            visibleModalPreview: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',
            data: [],

            cd_crianca: '', 
            cd_topico: '', 
            cd_subtopico: '', 
            cd_item: '', 
            cd_tema: '', 
            id_crianca_paitipo: '', 
            id_crianca_ordem: '', 
            ds_crianca_title: '', 
            ds_crianca_descricao: '',

            optionsTopico: [],
            optionsSubtopico: [],
            optionsItem: [],
            optionsPaitipo: [
                { id: 1, title: 'Tópico' },
                { id: 2, title: 'Subtópico' },
                { id: 3, title: 'Item de subtópico' },
            ],

            tabs: ['Temas', 'Tópicos', 'Subtópicos', 'Pontos','Criança'],
            displaySubtopico: '',
            itemDelValue: '', 
            itemDelDisplay: '', 
            filteredData: null,
            selectedItem: null,
            regperpage: 10,
            totalReg: 0,
            totalPages: 1,
            regini: 0,
            regfim: 0,
            currentPage: 1,
            reloadForm: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        }, () => {
            //console.log(this.state.id_crianca_paitipo)
        });
    }

    componentWillMount(){
        const par = this.props.match.params;
        
        if (par.id !== undefined) {
            this.setState({
                cd_tema: par.id
            }, () => {
                this.consultaAPI();
                this.fetchData();
            });
        }
    }
    
    changeCurrentPage = numPage => {
        this.setState({ currentPage: numPage });
    };

    consultaAPI = async(act) => {
        
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
    
            try { 
    
                this.setState({ isLoading: true });
                
                var response2 = await api.get(`/comboSubtopico/${this.state.cd_tema}`);
                var response3 = await api.get(`/comboTopico/${this.state.cd_tema}`);
                var response4 = await api.get(`/comboItem/${this.state.cd_tema}`);
                
                Promise.all([
                    response2,
                    response3,
                    response4,
                ]).then((values) => {
                    
                    this.setState({ 
                        isLoading: false,
                    })

                    let promisse2 = values[0];
                    let promisse3 = values[1];
                    let promisse4 = values[2];
                    /**
                     * Segunda Promisse - Combo Tipo usuario
                    */
                    if (promisse2 !== undefined && promisse2.data !== null) {
                    
                        const p = promisse2.data.data || [];

                        p.unshift({
                            id: '',
                            title: 'Selecione...'
                        })

                        this.setState({
                            optionsSubtopico: p
                        }); 
                    } 
                    if (promisse3 !== undefined && promisse3.data !== null) {
                    
                        const p = promisse3.data.data || [];

                        p.unshift({
                            id: '',
                            title: 'Selecione...'
                        })

                        this.setState({
                            optionsTopico: p
                        }); 
                    } 
                    if (promisse4 !== undefined && promisse4.data !== null) {
                    
                        const p = promisse4.data.data || [];

                        p.unshift({
                            id: '',
                            title: 'Selecione...'
                        })

                        this.setState({
                            optionsItem: p
                        }); 
                    } 
                });
          
            } catch (response) { 
                console.log(response)
                this.setState({ isLoading: false, tooltip2: "Houve um erro"});
            }
        }

    /* LISTAGEM DE DADOS */
    fetchData = async() => {

        try { 

            this.setState({ isLoading: true });
            
            const response = await api.get(`/crianca/${this.state.cd_tema}`);
            
            console.log(response)
            if( response.data !== undefined ){

                let dados = response.data.data;

                this.setState({ 
                    isLoading: false,
                    data: dados,
                    totalReg: dados.length
                });
      
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.."});
            }
      
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    selectItem = (item) => {
        
        this.setState({
            cd_crianca: item.cd_crianca,
            id_crianca_paitipo: item.id_crianca_paitipo,
            cd_topico: item.cd_pai,
            cd_subtopico: item.cd_pai,
            cd_item: item.cd_pai,
            id_crianca_ordem: item.id_crianca_ordem,
            ds_crianca_title: item.ds_crianca_title,
            ds_crianca_descricao: item.ds_crianca_descricao,
        });
    }

    clearSelectItem = () => {
        this.setState({
            submittedForm: false,
            cd_crianca: '',
            id_crianca_paitipo: '',
            cd_pai: '',
            cd_topico: '', 
            cd_subtopico: '', 
            cd_item: '', 
            id_crianca_ordem: '', 
            ds_crianca_title: '', 
            ds_crianca_descricao: '',
        })
    }

    renderItem = (item) => {
        return (
            <tr key={item.cd_crianca}>
                <td className="tdClickable" onClick={() => this.confirmRemoveItem(item)}>
                    <div className="deletar"><i className="mdi mdi-24px mdi-delete-circle" style={{color:'#f2545b'}}></i></div>
                </td>
                <td className="tdClickable" onClick={() => this.selectItem(item)}>
                    <i className="mdi mdi-24px mdi-pencil-circle"></i>
                </td>
                
                <td className="tdColumn">{ item.id_crianca_ordem }</td>
                <td className="tdColumn">{ item.ds_crianca_title }</td>
                <td className="tdColumn">{ item.ds_crianca_descricao }</td>
            </tr>
        )
    }

    renderItems = () => {
        const { data, currentPage, regperpage } = this.state;
        
        let regfim = currentPage * regperpage;
        let regini = regfim - regperpage;
        
        if( data.length > 0 ){
            return data.map((item, index) => {
                if (index >= regini && index < regfim)
                    return this.renderItem(item);
            })  
        } else {
            return (
                <tr>
                    <td colSpan="5">Nenhum registro encontrado</td>
                </tr>
            )
        }
    }

    validation = () => {
        const { ds_crianca_title, id_crianca_ordem, cd_pai, id_crianca_paitipo } = this.state;

        if (ds_crianca_title === '' || id_crianca_ordem === '' || id_crianca_paitipo === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ 
            submittedForm: true, 
        });
        
        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
        
        try { 

            var cd_pai  = this.state.cd_pai;

            switch (parseInt(this.state.id_crianca_paitipo)) {
                case 1: var cd_pai = this.state.cd_topico; break;
                case 2: var cd_pai = this.state.cd_subtopico; break;
                case 3: var cd_pai = this.state.cd_item; break;
                default: break;
            }
            
            if( this.state.cd_crianca != '' ){
                var response = await api.put(`/crianca/${cd_pai}/${this.state.cd_crianca}`,this.state);
            } else {
                var response = await api.post(`/crianca/${this.state.cd_tema}/${cd_pai}`,this.state);
            }

            console.log(response)
            
            if( response.data.errors === "" ){    
                if (this.state.cd_crianca != '') {
                    toast.success('Registro editado com sucesso', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Registro inserido com sucesso', { autoClose: 3000, toastId: 1 });
                }

                this.fetchData();
                this.clearSelectItem();
            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    confirmRemoveItem = ({ cd_crianca }) => {
        this.setState({ 
            visibleModal: !this.state.visibleModal,
            itemDelValue: cd_crianca, 
        })
    }

    removeItem = async (id) => {
        try { 

            const response = await api.delete(`/crianca/${id}`);
            console.log(response)
            if( response.data.errors === "" ){
                this.fetchData();
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.."});
            }

            this.setState({ visibleModal: false })
      
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    toggleModal = () => {
        this.setState({ visibleModal: !this.state.visibleModal })
    }   

    toggleModalPreview = () => {
        this.setState({ visibleModalPreview: !this.state.visibleModalPreview })
    }   

    toggleImg = () => {
        this.setState(prevState => ({ popoverOpenImg: !prevState.popoverOpenImg }));
    };

    sendImage = (files) => {
        if (files.base64 !== '') {
            this.setState({
                ds_crianca_img64 : files.base64
            });
        }
    }

    clearImg = () => {
        
        this.setState({
            ds_crianca_img64: ''
        }, () => console.log(this.state));
    }

    returnImg = (imagem) => {
        
        if (imagem === undefined || imagem === null)
            imagem = '';

        const { popoverOpenImg } = this.state;
        return (imagem !== '') ? (
            <div style={{ width: '100%' }}>
                <Button className="btn btnSalvarConteudo btn-primary" id="PopoverClick" type="button" style={{ color: 'white', float: 'left' }}>
                    Ver imagem
                </Button>
                <Button className="btn btnSalvarConteudo btn-danger" onClick={this.clearImg}  type="button" style={{ color: 'white', float: 'left', marginLeft: 10 }}>
                    Deletar imagem
                </Button>
                <Popover
                placement="right"
                isOpen={popoverOpenImg}
                target="PopoverClick"
                toggle={this.toggleImg}
                dir="right"
                >
                    <PopoverHeader>
                        Visualizar Imagem
                        <div style={{ float: 'right', cursor: 'pointer', marginTop: -5 }} onClick={this.toggleImg}>
                            <i className="mdi mdi-24px mdi-close"></i>
                        </div>
                    </PopoverHeader>
                    <PopoverBody>
                        <img src={`${imagem}`} style={{ width: '100%', height: '100%' }} alt="Imagem enviada" />
                    </PopoverBody>
                </Popover>
            </div>
        ) : (null)
    }

    selectTab = (index) => {
        switch (index){
            case 0: this.props.history.push(`/tema/form/${this.state.cd_tema}`); break;
            case 1: this.props.history.push(`/topico/${this.state.cd_tema}`); break;
            case 2: this.props.history.push(`/subtopico/${this.state.cd_tema}`); break;
            case 3: this.props.history.push(`/itemsubtopico/${this.state.cd_tema}`); break;
            case 4: this.props.history.push(`/crianca/${this.state.cd_tema}`); break;
        }
    }
    
    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    render(){

        const { visibleModal, visibleModalPreview, itemDelValue, tabs, ds_crianca_img64, submittedForm, id_crianca_paitipo } = this.state;
        const img64 = (ds_crianca_img64 === undefined || ds_crianca_img64 === null) ? '' : ds_crianca_img64;
        
        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>Prescrições</h2>
                                        </div>

                                        <div className="float-right">
                                            <Button type='button' className="btn-primary btn btn-md m-b-15 ml-2 mr-2" onClick={this.toggleModalPreview}>
                                                <i className="mdi mdi-tree-menu"></i> Preview
                                            </Button>
                                        </div>
                                        
                                        <div className="float-right">
                                            <Link to={`/tema/form/`} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Novo Tema
                                            </Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Modal isOpen={visibleModalPreview} size='lg' toggle={this.toggleModalPreview}>
                                            <ModalHeader toggle={this.toggleModalPreview}>Preview</ModalHeader>
                                            <ModalBody style={{ minHeight: 300, maxHeight: 500, overflow: 'auto' }}>
                                                <Preview cd_tema={this.state.cd_tema} />
                                            </ModalBody>
                                        </Modal>
                                        <Modal isOpen={visibleModal} toggle={this.toggleModal}>
                                            <ModalHeader toggle={this.toggleModal}>Confirmação</ModalHeader>
                                            <ModalBody>
                                            Tem certeza que deseja excluir este registro?
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="danger" onClick={() => this.removeItem(itemDelValue)}>Sim, com certeza</Button>
                                                <Button color="primary" onClick={this.toggleModal}>Não</Button>
                                            </ModalFooter>
                                        </Modal>
                                        <Tabs 
                                            fnCallback={this.selectTab}
                                            selected={4}
                                            campos={tabs}
                                        />
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_crianca_title">Título:</label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_crianca_title" 
                                                        className="form-control"
                                                        value={this.state.ds_crianca_title}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.ds_crianca_title === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_crianca_descricao">Descrição:</label>
                                                    <textarea 
                                                        name="ds_crianca_descricao" 
                                                        className="form-control" 
                                                        style={{ height: 120, verticalAlign: 'top' }}
                                                        value={this.state.ds_crianca_descricao}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row" >
                                            <div className="form-group col-md-5">
                                                    <label htmlFor="id_crianca_paitipo">Relacionamento:<span className="danger">*</span></label>
                                                    <select
                                                        name="id_crianca_paitipo" 
                                                        placeholder={'Selecione...'} 
                                                        value={this.state.id_crianca_paitipo}
                                                        onChange={this.handleInputChange} 
                                                        className="form-control" 
                                                    >
                                                        <option value=''>Selecione...</option>
                                                        { 
                                                            this.state.optionsPaitipo.map((item) => {
                                                                return (
                                                                    <option value={item.id}>{item.title}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    { submittedForm && this.state.cd_subtopico === '' && this.requiredField()}
                                                </div>
                                                
                                                { id_crianca_paitipo == 1 && 
                                                    <div className="form-group col-md-5">
                                                        <label htmlFor="cd_topico">Tópico:<span className="danger">*</span></label>
                                                        <select
                                                            name="cd_topico" 
                                                            value={this.state.cd_topico}
                                                            onChange={this.handleInputChange} 
                                                            className="form-control" 
                                                        >
                                                            { 
                                                                this.state.optionsTopico.map((item) => {
                                                                    return (
                                                                        <option value={item.id}>{item.title}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        { submittedForm && this.state.cd_topico === '' && this.requiredField()}
                                                    </div>
                                                }
                                                { id_crianca_paitipo == 2 && 
                                                    <div className="form-group col-md-5">
                                                        <label htmlFor="cd_subtopico">Subtópico:<span className="danger">*</span></label>
                                                        <select
                                                            name="cd_subtopico" 
                                                            placeholder={this.state.displaySubtopico || 'Selecione...'} 
                                                            value={this.state.cd_subtopico}
                                                            onChange={this.handleInputChange} 
                                                            className="form-control" 
                                                        >
                                                            { 
                                                                this.state.optionsSubtopico.map((item) => {
                                                                    return (
                                                                        <option value={item.id}>{item.title}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        { submittedForm && this.state.cd_subtopico === '' && this.requiredField()}
                                                    </div>
                                                }
                                                { id_crianca_paitipo == 3 && 
                                                    <div className="form-group col-md-5">
                                                        <label htmlFor="cd_item">Item:<span className="danger">*</span></label>
                                                        <select
                                                            name="cd_item"  
                                                            value={this.state.cd_item}
                                                            onChange={this.handleInputChange} 
                                                            className="form-control" 
                                                        >
                                                            { 
                                                                this.state.optionsItem.map((item) => {
                                                                    return (
                                                                        <option value={item.id}>{item.title}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        { submittedForm && this.state.cd_item === '' && this.requiredField()}
                                                    </div>
                                                }
                                                <div className="form-group col-md-2">
                                                    <label className="font-secondary">Ordem:</label>
                                                    <input 
                                                        type="text" 
                                                        name="id_crianca_ordem" 
                                                        className="form-control" 
                                                        value={this.state.id_crianca_ordem}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.id_crianca_ordem === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            
                                            <div className="form-group float-left">
                                                <div onClick={this.clearSelectItem} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                Novo
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                        <div className="table-responsive m-t-20">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th style={{ width: 10 }}></th>
                                                    <th style={{ width: 10 }}></th>
                                                    <th>Ordem</th>
                                                    <th>Título Criança</th>
                                                    <th>Descrição Criança</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    { this.renderItems() }
                                                </tbody>
                                            </table>
                                            <Pagination
                                                currentPage={this.state.currentPage}
                                                totalPages={this.state.totalReg}
                                                totalSize={this.state.totalReg}
                                                sizePerPage={this.state.regperpage}
                                                changeCurrentPage={this.changeCurrentPage}
                                                theme="bootstrap"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}