import React, { Component } from 'react';
import api from '../services';

// import { Container } from './styles';

export default class Preview extends Component {
    state = {
        data: [],
        ds_tema: '',
        ds_tema_sintoma: ''
    }

    componentWillMount() {
        this.fetchData();
    }

    fetchData = async() => {

        try { 

            const response = await api.get(`/preview/${this.props.cd_tema}`);
            
            if( response.data !== undefined ){

                let dados = response.data.data;

                this.setState({ 
                    data: dados,
                    ds_tema: response.data.tema.ds_tema,
                    ds_tema_sintoma: response.data.tema.ds_tema_sintoma,
                });
      
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.."});
            }
      
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    renderList = () => {
        const { data } = this.state;
        const dados = JSON.parse(data);
        return (
            <ul style={{ width: '100%', listStyleType: 'decimal' }}>
            {
                dados.map((topico, indexTopico) => {
                    return (
                        <li>
                            <div style={{ fontWeight: 'bold' }}>{ topico.ds_topico_title }</div>
                            <div>{ topico.ds_topico_descricao }</div>
                            { topico.ds_topico_nota !== '' && <div className='nota'>{ topico.ds_topico_nota }</div> }
                            { topico.ds_topico_img64 && 
                                <div>
                                <img src={`${topico.ds_topico_img64}`} style={{ height: 200,  }} alt="Imagem enviada" />
                                </div>
                            }
                            { topico.ds_topico_tabela && 
                                <div
                                style={{marginTop: '1em', marginBottom: '1em', width: '80%'}}
                                dangerouslySetInnerHTML={{
                                    __html: topico.ds_topico_tabela
                                }}></div>
                            }
                            { this.renderTopicoCrianca(topico) }
                            { indexTopico <= data.length ? this.renderSubtopico(topico) : '' }
                        </li>
                    )
                })
            }
            </ul>
        )
    }

    renderSubtopico = (sub) => {
        return (
            
            <ul style={{ width: '100%', listStyleType: 'lower-alpha' }}>
            {
                sub.children.map((subtopico, indexSubtopico) => {
                    return (
                        <li>
                            <div style={{ fontWeight: 'bold' }}>{ subtopico.ds_subtopico_title }</div>
                            <div>{ subtopico.ds_subtopico_descricao }</div>
                            { subtopico.ds_subtopico_nota !== '' && <div className='nota'>{ subtopico.ds_subtopico_nota }</div> }
                            { subtopico.ds_subtopico_img64 && 
                                <div>
                                <img src={`${subtopico.ds_subtopico_img64}`} style={{ height: 200, }} alt="Imagem enviada" />
                                </div>
                            }
                            { subtopico.ds_subtopico_tabela && 
                                <div
                                style={{marginTop: '1em', marginBottom: '1em', width: '80%'}}
                                dangerouslySetInnerHTML={{
                                    __html: subtopico.ds_subtopico_tabela
                                }}></div>
                            }
                            { this.renderSubtopicoCrianca(subtopico) }
                            { indexSubtopico <= sub.children.length ? this.renderItem(subtopico) : '' }
                        </li>
                    )
                })
            }
            </ul>
        )
    }

    renderItem = (item) => {
        
        item = item === undefined ? '' : item;
        
        return (
            <ul style={{ listStyleType: 'disc' }}>
            {
                item.children.map((item) => {
                    return (
                        <li>
                            <div style={{ fontWeight: 'bold' }}>{ item.ds_item_title }</div>
                            { item.ds_item_descricao }
                            { item.ds_item_nota !== '' && <div className='nota'>{ item.ds_item_nota }</div> }
                            { item.ds_item_img64 && 
                                <div>
                                <img src={`${item.ds_item_img64}`} style={{ width: 200, height: 200, }} alt="Imagem enviada" />
                                </div>
                            }
                            { item.ds_item_tabela && 
                                <div
                                style={{marginTop: '1em', marginBottom: '1em', width: '80%'}}
                                dangerouslySetInnerHTML={{
                                    __html: item.ds_item_tabela
                                }}></div>
                            }
                            { this.renderItemCrianca(item) }
                        </li>
                    )
                })
            }
            </ul>
        )
    }

    renderTopicoCrianca = (topico) => {
        if (topico.crianca.length == 0)
            return false;

        return topico.crianca.length === 1 ? (
            <ul className='criancaBox'>
            {
                topico.crianca.map((crianca) => {
                    return (
                        <li>
                            <div style={{ fontWeight: 'bold' }}>{ crianca.ds_crianca_title }</div>
                            { crianca.ds_crianca_descricao }
                        </li>
                    )
                })
            }
            </ul>
        ) : (
            <ul className='criancaBox' style={{ listStyleType: 'lower-alpha' }}>
            {
                topico.crianca.map((crianca) => {
                    return (
                        <li>
                            <div style={{ fontWeight: 'bold' }}>{ crianca.ds_crianca_title }</div>
                            { crianca.ds_crianca_descricao }
                        </li>
                    )
                })
            }
            </ul>
        )
    }

    renderSubtopicoCrianca = (subtopico) => {
        if (subtopico.crianca.length == 0)
            return false;
        
        return subtopico.crianca.length === 1 ? (
            <ul className='criancaBox'>
            {
                subtopico.crianca.map((crianca) => {
                    return (
                        <li>
                            <div style={{ fontWeight: 'bold' }}>{ crianca.ds_crianca_title }</div>
                            { crianca.ds_crianca_descricao }
                        </li>
                    )
                })
            }
            </ul>
        ) : (
            <ul className='criancaBox' style={{ listStyleType: 'lower-alpha' }}>
            {
                subtopico.crianca.map((crianca) => {
                    return (
                        <li>
                            <div style={{ fontWeight: 'bold' }}>{ crianca.ds_crianca_title }</div>
                            { crianca.ds_crianca_descricao }
                        </li>
                    )
                })
            }
            </ul>
        )
    }

    renderItemCrianca = (item) => {
        if (item.crianca.length == 0)
            return false;
        
        return item.crianca.length === 1 ? (
            <ul className='criancaBox'>
            {
                item.crianca.map((crianca) => {
                    return (
                        <li>
                            <div style={{ fontWeight: 'bold' }}>{ crianca.ds_crianca_title }</div>
                            { crianca.ds_crianca_descricao }
                        </li>
                    )
                })
            }
            </ul>
        ) : (
            <ul className='criancaBox' style={{ listStyleType: 'lower-alpha' }}>
            {
                item.crianca.map((crianca) => {
                    return (
                        <li>
                            <div style={{ fontWeight: 'bold' }}>{ crianca.ds_crianca_title }</div>
                            { crianca.ds_crianca_descricao }
                        </li>
                    )
                })
            }
            </ul>
        )
    }

    render () {
        const { data } = this.state;
        
        return (
            <div className="previewList">
                <h3 style={{ marginLeft: 10, color: '#42acd9' }}>{this.state.ds_tema}</h3>
                {this.state.ds_tema_sintoma !== '' && <p style={{ marginLeft: 10 }}><strong>Sintomas:</strong> {this.state.ds_tema_sintoma}</p>}
                { data.length > 0 && this.renderList()}
            </div>
        )
    }
}
