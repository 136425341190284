import React from "react";
import { Link, withRouter } from "react-router-dom";
import api from "../../services";

class SideMenu extends React.Component {
  state = {
    data: [],
    activeMenu: "",
  };

  componentWillMount() {
    this.getActiveMenu();
    const id = localStorage.getItem("@guiabasico/cd_usuario");
    console.log('', );
    this.fetchData(id);
  }

  fetchData = async (id) => {
    try {
      this.setState({ isLoading: true });

      const response = await api.get(`/permissoes/${id}`);

      console.log(response);
      if (response.data.data !== undefined) {
        let dados = response.data.data;

        console.log("response.data", response.data);

        this.setState({
          data: dados,
        });
      }
    } catch (response) {
      console.log(response);
    }
  };

  getActiveMenu = () => {
    const { pathname } = this.props.location;
    var resp = pathname;

    if (
      pathname.indexOf("/grupousuario") !== -1 ||
      pathname.indexOf("/funcaosistema") !== -1 ||
      pathname.indexOf("/usuario") !== -1
    ) {
      resp = "seguranca";
    }
    if (
      pathname.indexOf("/formapgto") !== -1 ||
      pathname.indexOf("/categoriamedicamento") !== -1
    ) {
      resp = "configuracoes";
    }
    if (
      pathname.indexOf("/tema") !== -1 ||
      pathname.indexOf("/termosdeuso") !== -1 ||
      pathname.indexOf("/sobre") !== -1 ||
      pathname.indexOf("/referencia") !== -1 ||
      pathname.indexOf("/instagram") !== -1 ||
      pathname.indexOf("/medicamento") !== -1
    ) {
      resp = "conteudo";
    }

    this.setState({
      activeMenu: resp,
    });
  };

  render() {
    const { data, activeMenu } = this.state;

    return (
      <div>
        <img
          id="logoReduzida"
          className="mostrarLogoPequena"
          src="/assets/img/logo_reduzida.png"
          width="32"
          alt="GB"
        />

        <aside className="admin-sidebar">
          <div className="admin-sidebar-brand">
            <img
              className="admin-brand-logo"
              id="logoNormal"
              src="/assets/img/logo.png"
              width="150"
              alt="GB"
            />

            <div className="ml-auto">
              {/* sidebar pin*/}
              <a
                href="#"
                className="admin-pin-sidebar btn-ghost btn btn-rounded-circle"
              ></a>
              {/* sidebar close for mobile device*/}
              <a href="#" className="admin-close-sidebar"></a>
            </div>
          </div>
          <div className="admin-sidebar-wrapper js-scrollbar">
            <ul className="menu">
              <li className="menu-item active ">
                <Link to="/inicio" className=" menu-link">
                  <span className="menu-label">
                    <span className="menu-name">Início</span>
                  </span>
                  <span className="menu-icon">
                    <i className="icon-placeholder mdi mdi-home "></i>
                  </span>
                </Link>
              </li>

              {(data.indexOf(2) !== -1 || data.length === 0) && (
                <li
                  className={
                    activeMenu === "seguranca"
                      ? "menu-item opened"
                      : "menu-item"
                  }
                >
                  <a href="#" className="open-dropdown menu-link">
                    <span className="menu-label">
                      <span className="menu-name">
                        Segurança
                        <span className="menu-arrow"></span>
                      </span>
                    </span>
                    <span className="menu-icon">
                      <i className="icon-placeholder mdi mdi-security "></i>
                    </span>
                  </a>

                  <ul
                    className={
                      activeMenu === "seguranca"
                        ? "sub-menu opened"
                        : "sub-menu"
                    }
                    style={{
                      display: activeMenu === "seguranca" ? "block" : "",
                    }}
                  >
                    <Link to="/grupousuario" className=" menu-link">
                      <span className="menu-label">
                        <span className="menu-name">Grupo de Usuários</span>
                      </span>
                    </Link>
                    <Link to="/funcaosistema" className=" menu-link">
                      <span className="menu-label">
                        <span className="menu-name">Funções de Sistema</span>
                      </span>
                    </Link>
                    <li className="menu-item">
                      <Link to="/usuario" className=" menu-link">
                        <span className="menu-label">
                          <span className="menu-name">Usuários</span>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              {(data.indexOf(3) !== -1 || data.length === 0) && (
                <li
                  className={
                    activeMenu === "configuracoes"
                      ? "menu-item opened"
                      : "menu-item"
                  }
                >
                  <a href="#" className="open-dropdown menu-link">
                    <span className="menu-label">
                      <span className="menu-name">
                        Configurações
                        <span className="menu-arrow"></span>
                      </span>
                    </span>
                    <span className="menu-icon">
                      <i className="icon-placeholder fe fe-sliders "></i>
                    </span>
                  </a>
                  {/*submenu*/}
                  <ul
                    className={
                      activeMenu === "configuracoes"
                        ? "sub-menu opened"
                        : "sub-menu"
                    }
                    style={{
                      display: activeMenu === "configuracoes" ? "block" : "",
                    }}
                  >
                    <Link to="/formapgto" className=" menu-link">
                      <span className="menu-label">
                        <span className="menu-name">Forma de Pagamento</span>
                      </span>
                    </Link>

                    <Link to="/categoriamedicamento" className=" menu-link">
                      <span className="menu-label">
                        <span className="menu-name">
                          Categoria de Medicamento
                        </span>
                      </span>
                    </Link>
                  </ul>
                </li>
              )}
              {(data.indexOf(4) !== -1 || data.length === 0) && (
                <li className="menu-item ">
                  <Link to="/cliente" className=" menu-link">
                    <span className="menu-label">
                      <span className="menu-name">Clientes</span>
                    </span>
                    <span className="menu-icon">
                      <i className="icon-placeholder mdi mdi-account-group"></i>
                    </span>
                  </Link>
                </li>
              )}
              {(data.indexOf(5) !== -1 || data.length === 0) && (
                <li className="menu-item ">
                  <Link to="/prospect" className=" menu-link">
                    <span className="menu-label">
                      <span className="menu-name">Prospects</span>
                    </span>
                    <span className="menu-icon">
                      <i className="icon-placeholder mdi mdi-account-group"></i>
                    </span>
                  </Link>
                </li>
              )}
              {(data.indexOf(6) !== -1 || data.length === 0) && (
                <li
                  className={
                    activeMenu === "conteudo" ? "menu-item opened" : "menu-item"
                  }
                >
                  <a href="#" className="open-dropdown menu-link">
                    <span className="menu-label">
                      <span className="menu-name">
                        Conteúdo
                        <span className="menu-arrow"></span>
                      </span>
                    </span>
                    <span className="menu-icon">
                      <i className="icon-placeholder mdi mdi-calendar-text"></i>
                    </span>
                  </a>
                  {/*submenu*/}
                  <ul
                    className={
                      activeMenu === "conteudo" ? "sub-menu opened" : "sub-menu"
                    }
                    style={{
                      display: activeMenu === "conteudo" ? "block" : "",
                    }}
                  >
                    <li className="menu-item">
                      <Link to="/tema" className=" menu-link">
                        <span className="menu-label">
                          <span className="menu-name">Prescrições</span>
                        </span>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/medicamento" className=" menu-link">
                        <span className="menu-label">
                          <span className="menu-name">Medicamentos</span>
                        </span>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/prova" className=" menu-link">
                        <span className="menu-label">
                          <span className="menu-name">Provas</span>
                        </span>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/termosdeuso" className=" menu-link">
                        <span className="menu-label">
                          <span className="menu-name">Termos de Uso</span>
                        </span>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/sobre" className=" menu-link">
                        <span className="menu-label">
                          <span className="menu-name">Sobre</span>
                        </span>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/referencia" className=" menu-link">
                        <span className="menu-label">
                          <span className="menu-name">Referências</span>
                        </span>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/instagram" className=" menu-link">
                        <span className="menu-label">
                          <span className="menu-name">Instagram</span>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              {(data.indexOf(7) !== -1 || data.length === 0) && (
                <li className="menu-item ">
                  <Link to="/venda" className=" menu-link">
                    <span className="menu-label">
                      <span className="menu-name">Vendas</span>
                    </span>
                    <span className="menu-icon">
                      <i className="icon-placeholder mdi mdi-cart-outline"></i>
                    </span>
                  </Link>
                </li>
              )}
              {(data.indexOf(8) !== -1 || data.length === 0) && (
                <li className="menu-item ">
                  <Link to="/faleconosco" className=" menu-link">
                    <span className="menu-label">
                      <span className="menu-name">Fale Conosco</span>
                    </span>
                    <span className="menu-icon">
                      <i className="icon-placeholder mdi mdi-email"></i>
                    </span>
                  </Link>
                </li>
              )}
              {(data.indexOf(9) !== -1 || data.length === 0) && (
                <li className="menu-item ">
                  <Link to="/notificacao" className=" menu-link">
                    <span className="menu-label">
                      <span className="menu-name">Notificações Push</span>
                    </span>
                    <span className="menu-icon">
                      <i className="icon-placeholder mdi mdi-bullhorn-outline"></i>
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </aside>
      </div>
    );
  }
}

export default withRouter(SideMenu);
