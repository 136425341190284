import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../services";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submittedForm: false,
      formError: false,
      emailEnviado: false,
      email: "",
      login: "",
      senha: "",
      act: "login",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const cd_usuario = localStorage.getItem("@guiabasico/cd_usuario");
    console.log("cd_usuario >>>>", cd_usuario);
    if (cd_usuario !== null) {
      this.props.history.push("/inicio");
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  validation = () => {
    const { login, senha } = this.state;

    if (login === "" || senha === "") {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Função padrão para envio de Formulário
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      submittedForm: true,
    });

    if (!this.validation()) {
      return false;
    }
    /* Função padrão para popular objetos na tela */
    /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
    /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

    try {
      console.log(this.state);
      const response = await api.post(`/login/`, this.state);

      console.log("response.data.errors", response.data.errors);
      if (!response.data.errors) {
        localStorage.setItem(
          "@guiabasico/cd_usuario",
          response.data.data.cd_usuario
        );
        this.props.history.push("/inicio");
      } else {
        console.log("err 1");
        toast.error(response.data.errors ?? "", {
          autoClose: 3000,
          toastId: 1,
        });
      }
    } catch (resp) {
      console.log(resp);
      this.setState({ isLoading: false, tooltip2: "Houve um erro" });
    }
  };
  handleEsqueci = async (event) => {
    event.preventDefault();

    if (this.state.email === "") {
      console.log("err 2");
      toast.error("Preencha o e-mail", { autoClose: 3000, toastId: 1 });
      return false;
    }
    /* Função padrão para popular objetos na tela */
    /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
    /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

    try {
      console.log(this.state);
      const response = await api.post(`/esqueci/`, this.state);

      if (response.data.errors === "") {
        this.setState({
          emailEnviado: true,
        });
      } else {
        console.log("err 3");
        toast.error(response.data.errors, { autoClose: 3000, toastId: 1 });
      }
    } catch (resp) {
      console.log(resp);
      toast.error("Dificuldades Técnicas", { autoClose: 3000, toastId: 1 });
    }
  };

  requiredField = () => {
    return <div className="requiredField">* Campo requerido</div>;
  };

  esqueci = () => {
    this.setState({ act: "esqueci" });
  };
  voltar = () => {
    this.setState({ act: "login" });
  };

  renderLogin = () => {
    const { submittedForm } = this.state;
    return (
      <>
        <h3 className="text-center">Guia Básico de Plantãoposda</h3>
        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
          <div className="form-group">
            <div className="input-group input-group-flush mb-3">
              <input
                required
                name="login"
                type="input"
                className="form-control form-control-prepended"
                placeholder="Digite seu usuário"
                value={this.state.login}
                onChange={this.handleInputChange}
              />
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span className=" mdi mdi-account"></span>
                </div>
              </div>
            </div>
            {submittedForm && this.state.login === "" && this.requiredField()}
          </div>
          <div className="form-group">
            <div className="input-group input-group-flush mb-3">
              <input
                required
                name="senha"
                type="password"
                className="form-control form-control-prepended"
                placeholder="Digite sua senha"
                value={this.state.senha}
                onChange={this.handleInputChange}
              />
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span className=" mdi mdi-key "></span>
                </div>
              </div>
            </div>
            {submittedForm && this.state.senha === "" && this.requiredField()}
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn text-uppercase btn-block  btn-info"
            >
              Entrar
            </button>
          </div>
          <div style={{ cursor: "pointer" }} onClick={() => this.esqueci()}>
            <p className="small">Esqueceu sua senha?</p>
          </div>
        </form>
      </>
    );
  };
  renderEsqueci = () => {
    const { emailEnviado } = this.state;
    return (
      <>
        <h3 className="text-center">Guia Básico de Plantão</h3>
        <form onSubmit={this.handleEsqueci} className="formGeral m-t-30">
          {!emailEnviado && (
            <>
              <div className="form-group">
                <div className="input-group input-group-flush mb-3">
                  <input
                    required
                    name="email"
                    type="input"
                    className="form-control form-control-prepended"
                    placeholder="Digite o email cadastrado"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <span className=" mdi mdi-account"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn text-uppercase btn-block  btn-info"
                >
                  Enviar Senha
                </button>
              </div>
            </>
          )}
          {emailEnviado && (
            <p style={{ textAlign: "center" }}>E-mail enviado</p>
          )}
          <div style={{ cursor: "pointer" }} onClick={() => this.voltar()}>
            <p className="small">Voltar</p>
          </div>
        </form>
      </>
    );
  };

  render() {
    const { act } = this.state;

    document.body.className = "jumbo-page";
    return (
      <main className="admin-main  bg-pattern">
        <div className="container">
          <div className="row m-h-100 ">
            <div className="col-md-8 col-lg-4 m-auto">
              <div className="card shadow-lg ">
                <div className="card-body ">
                  <div className=" padding-box-2 ">
                    <div className="text-center p-b-20 pull-up-sm">
                      <div className="avatar avatar-lg ">
                        <img
                          className="avatar-img"
                          src="/assets/img/logo_reduzida.png"
                          width="32"
                          alt="GB"
                        />
                      </div>
                    </div>
                    {act === "login" && this.renderLogin()}
                    {act === "esqueci" && this.renderEsqueci()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
