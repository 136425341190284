import React, { Component } from 'react'

import api from '../../services';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';

toast.configure();

export default class Instagram extends Component{
    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: true,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',

            ds_instagram: '', 
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        
        const target = event.target;
        const name = target.name;
        var value2 = target.type === 'checkbox' ? target.checked : target.value;
        
        this.setState({
            [name]: value2
        });
    }

    componentDidMount(){

        this.fetchData();
    }

    fetchData = async() => {

        try { 

            this.setState({ isLoading: true });
            
            const response = await api.get('/instagram');
            
            if( response.data.data !== undefined ){

                let dados = response.data.data;

                this.setState({ 
                    isLoading: false,
                    ds_instagram: dados.ds_instagram
                });
      
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.."});
            }
      
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    validation = () => {
        const { ds_instagram } = this.state;

        var continuar = true;

        if (ds_instagram === '') {
            continuar = false;
        }

        return continuar;
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ 
            submittedForm: true, 
        });
        
        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
        
        try { 
            console.log(this.state)
            var response = await api.put(`/instagram`,this.state);
            
            if( response.data.errors === "" ){    
                
                toast.success('Registro editado com sucesso!', { autoClose: 3000, toastId: 1 });
            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }
    
    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    render(){
        const { submittedForm, isLoading } = this.state;

        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>Instagram</h2>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            
                                            
                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_instagram">Perfil:</label>
                                                    
                                                    <input 
                                                        type="text" 
                                                        name="ds_instagram" 
                                                        className="form-control"
                                                        value={this.state.ds_instagram}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.ds_instagram === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            
                                            
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}