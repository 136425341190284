import React, { Component } from 'react'

import api from '../../services';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';

toast.configure();

export default class TermosdeusoForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: true,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',

            cd_termosdeuso: 1, 
            ds_termosdeuso: '', 
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        
        const target = event.target;
        const name = target.name;
        var value2 = target.type === 'checkbox' ? target.checked : target.value;
        
        this.setState({
            [name]: value2
        });
    }

    componentWillMount(){

        this.consultaAPI();
    }

    consultaAPI = async(act) => {
        
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
    
        try { 

            this.setState({ isLoading: true });

            
            var response = await api.get(`/termosdeuso/${this.state.cd_termosdeuso}`);
            
            Promise.all([
                response,
            ]).then((values) => {
                
                this.setState({ 
                    isLoading: false,
                })

                let promisse1 = values[0];
                /**
                 * Segunda Promisse - Combo Tipo usuario
                */
                if (promisse1 !== undefined && promisse1.data !== null) {
                
                    let dados = promisse1.data.data;
                    
                    this.setState({  
                        ds_termosdeuso: dados.ds_termosdeuso || '', 
                    }, () => {
                        console.log('state ',this.state)
                    });
                } 
            });
        
        } catch (response) { 
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    validation = () => {
        const { ds_termosdeuso } = this.state;
        
        if (ds_termosdeuso === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ 
            submittedForm: true, 
        });
        
        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
        
        try { 
            console.log(this.state)
            
            var response = await api.put(`/termosdeuso/${this.state.cd_termosdeuso}`,this.state);

            console.log(response)
            
            if( response.data.errors === "" ){    
                
                toast.success('Termo editado com sucesso!', { autoClose: 3000, toastId: 1 });

                this.voltar();

            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }
    
    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    render(){
        const { submittedForm, isLoading } = this.state;
        
        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>Termos de uso</h2>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_termosdeuso">Descrição:</label>
                                                    <textarea 
                                                        type="text" 
                                                        name="ds_termosdeuso" 
                                                        className="form-control"
                                                        style={{ height: 400 }}
                                                        value={this.state.ds_termosdeuso}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.ds_termosdeuso === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}