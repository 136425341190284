import React, { Component } from 'react'

import api from '../../services';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/pt-br'; 

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';

toast.configure();

export default class RelacionamentoForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',

            cd_grupousuario: '', 
            arrFuncoes: [],
            arrSelecionadas: [],
            arrFuncoesSel: [],
            arrSelecionadasSel: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){

        const par = this.props.match.params;
        
        if (par.id !== undefined) {
            this.setState({
                cd_grupousuario: par.id
            }, () => {
                this.consultaAPI();
            });
        } else {
            this.consultaAPI();
        }
    }

    consultaAPI = async(act) => {
        
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
    
        try { 

            this.setState({ isLoading: true });

            var response2 = await api.get(`/comboGrupofuncao/${this.state.cd_grupousuario}`);
            var response = await api.get(`/comboRelacionamento/${this.state.cd_grupousuario}`);
            
            Promise.all([
                response,
                response2,
            ]).then((values) => {
                
                this.setState({ 
                    isLoading: false,
                })

                let promisse1 = values[0];
                let promisse2 = values[1];
                
                if (promisse1 !== undefined && promisse1.data !== null) {
                    
                    this.setState({
                        arrFuncoes: promisse1.data.data || []
                    }); 
                } 

                if (promisse2 !== undefined && promisse2.data !== null) {
                    
                    this.setState({
                        arrSelecionadas: promisse2.data.data || []
                    }); 
                } 
            });
        
        } catch (response) { 
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ 
            submittedForm: true, 
        });
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
        
        try { 
            console.log(this.state)
            
            var response = await api.post(`/updateRelacionamento/${this.state.cd_grupousuario}`,this.state);

            console.log(response)
            
            if( response.data.errors === "" ){    
                toast.success('Relacionado editado com sucesso!', { autoClose: 3000, toastId: 1 });

                this.voltar();

            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    voltar = () => {
        this.props.history.push('/grupousuario');
    }

    selectFuncao = (item) => {
        var sel = this.state.arrFuncoesSel;
        
        if (sel.indexOf(item) === -1) {
            sel.push(item);
        } else {
            sel = sel.filter((el) => {
                return el.id !== item.id
            });
        }
        
        this.setState({
            arrFuncoesSel: sel
        })
    }
    
    selectSelecionadas = (item) => {
        var sel = this.state.arrSelecionadasSel;
        
        if (sel.indexOf(item) === -1) {
            sel.push(item);
        } else {
            sel = sel.filter((el) => {
                return el.id !== item.id
            });
        }
        
        this.setState({
            arrSelecionadasSel: sel
        })
    }

    moveRight = () => {
        var sel = this.state.arrSelecionadas;
        var fnc = this.state.arrFuncoes;
        
        this.state.arrFuncoesSel.map((item) => {
            sel.push(item);

            fnc = fnc.filter((el) => {
                return el.id !== item.id
            });
        });
        
        this.setState({
            arrSelecionadas: sel,
            arrFuncoes: fnc,
        }, () => {
            this.setState({
                arrFuncoesSel: []
            })
        })
    }

    moveAllRight = () => {
        var sel = this.state.arrSelecionadas;
        var fnc = this.state.arrFuncoes;
        
        this.state.arrFuncoes.map((item) => {
            sel.push(item);
        });
        
        this.setState({
            arrSelecionadas: sel,
            arrFuncoes: [],
        }, () => {
            this.setState({
                arrFuncoesSel: [],
                arrSelecionadasSel: []
            })
        })
    }

    moveLeft = () => {
        var sel = this.state.arrFuncoes;
        var fnc = this.state.arrSelecionadas;
        
        this.state.arrSelecionadasSel.map((item) => {
            sel.push(item);

            fnc = fnc.filter((el) => {
                return el.id !== item.id
            });
        });
        
        this.setState({
            arrSelecionadas: fnc,
            arrFuncoes: sel,
        }, () => {
            this.setState({
                arrSelecionadasSel: [],
                arrFuncoesSel: [],
            })
        })
    }

    moveAllLeft = () => {
        var sel = this.state.arrFuncoes;
        var fnc = this.state.arrSelecionadas;
        
        this.state.arrSelecionadas.map((item) => {
            sel.push(item);
        });
        
        this.setState({
            arrSelecionadas: [],
            arrFuncoes: sel,
        }, () => {
            this.setState({
                arrFuncoesSel: [],
                arrSelecionadasSel: []
            })
        })
    }

    render(){
        const { submittedForm, arrFuncoes, arrSelecionadas } = this.state;
        
        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>{ this.state.cd_grupousuario === '' ? 'Novo Grupo de Usuário' : 'Grupos de Usuários'}</h2>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-5">
                                                    <label htmlFor="ds_grupousuario">Funções de Sistema:</label>
                                                    <div className="boxRelacionamento">
                                                    { arrFuncoes.map((item) => {
                                                        return (
                                                            <div 
                                                                className={ 
                                                                    this.state.arrFuncoesSel.some(it => it.id === item.id)
                                                                        ? "relacionamentoItem relacionamentoItemSel"
                                                                        : "relacionamentoItem"
                                                                }
                                                                onClick={() => this.selectFuncao(item)}
                                                            >
                                                                {item.title}
                                                            </div>
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-2" style={{ textAlign: 'center', paddingTop: 85 }}>
                                                    <button type="button" onClick={this.moveAllRight} className="btn btn-default col-md-8 col-md-offset-2 atr" style={{ marginBottom: 10 }}>
                                                        <span class="mdi mdiGray mdi-format-list-bulleted"></span>
                                                        <span class="mdi mdiGray mdi-chevron-right"></span>
                                                    </button><br />
                                                    <button type="button" onClick={this.moveRight} className="btn btn-default col-md-8 col-md-offset-2 atr" style={{ marginBottom: 10 }}>
                                                        <span class="mdi mdiGray mdi-chevron-right"></span>
                                                    </button><br />
                                                    <button type="button" onClick={this.moveLeft} className="btn btn-default col-md-8 col-md-offset-2 atr" style={{ marginBottom: 10 }}>
                                                        <span class="mdi mdiGray mdi-chevron-left"></span>
                                                    </button><br />
                                                    <button type="button" onClick={this.moveAllLeft} className="btn btn-default col-md-8 col-md-offset-2 atr" style={{ marginBottom: 10 }}>
                                                        <span class="mdi mdiGray mdi-chevron-left"></span>
                                                        <span class="mdi mdiGray mdi-format-list-bulleted"></span>
                                                    </button>
                                                </div>
                                                <div className="form-group col-md-5">
                                                    <label htmlFor="ds_grupousuario">Funções Selecionadas:</label>
                                                    <div className="boxRelacionamento">
                                                    { arrSelecionadas.map((item) => {
                                                        return (
                                                            <div 
                                                                className={ 
                                                                    this.state.arrSelecionadasSel.some(it => it.id === item.id)
                                                                        ? "relacionamentoItem relacionamentoItemSel"
                                                                        : "relacionamentoItem"
                                                                }
                                                                onClick={() => this.selectSelecionadas(item)}>
                                                                {item.title}
                                                            </div>
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}