import React, { Component } from 'react'

import api from '../../services';
import Pagination from "react-pagination-js";

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody, PopoverHeader, } from 'reactstrap';
import FileBase64 from 'react-file-base64';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Tabs from '../../components/Tabs';
import Preview from '../../components/Preview';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditorComponent from 'react-froala-wysiwyg';

// Import a single Froala Editor plugin.
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/colors.min.js';

// Import a language file.
import 'froala-editor/js/languages/pt_br.js';
toast.configure();


export default class Itemsubtopico extends Component{
    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            visibleModalPreview: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',
            data: [],

            cd_item: '', 
            cd_subtopico: '', 
            cd_tema: '', 
            id_item_ordem: '', 
            ds_item_nota: '', 
            ds_item_img64: '', 
            ds_item_tabela: '', 
            ds_item_title: '', 
            ds_item_descricao: '',

            options: [],
            tabs: ['Temas', 'Tópicos', 'Subtópicos', 'Pontos','Criança'],
            displaySubtopico: '',
            itemDelValue: '', 
            itemDelDisplay: '', 
            filteredData: null,
            selectedItem: null,
            regperpage: 10,
            totalReg: 0,
            totalPages: 1,
            regini: 0,
            regfim: 0,
            currentPage: 1,
            reloadForm: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    componentWillMount(){
        const par = this.props.match.params;
        
        if (par.id !== undefined) {
            this.setState({
                cd_tema: par.id
            }, () => {
                this.consultaAPI();
                this.fetchData();
            });
        }
    }
    
    changeCurrentPage = numPage => {
        this.setState({ currentPage: numPage });
    };

    consultaAPI = async(act) => {
        
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
    
            try { 
    
                this.setState({ isLoading: true });
                
                var response2 = await api.get(`/comboSubtopico/${this.state.cd_tema}`);
                
                Promise.all([
                    response2
                ]).then((values) => {
                    
                    this.setState({ 
                        isLoading: false,
                    })

                    let promisse2 = values[0];
                    /**
                     * Segunda Promisse - Combo Tipo usuario
                    */
                    if (promisse2 !== undefined && promisse2.data !== null) {
                    
                        const p = promisse2.data.data || [];

                        p.unshift({
                            id: '',
                            title: 'Selecione...'
                        })

                        this.setState({
                            options: p
                        }); 
                    } 
                });
          
            } catch (response) { 
                console.log(response)
                this.setState({ isLoading: false, tooltip2: "Houve um erro"});
            }
        }

    /* LISTAGEM DE DADOS */
    fetchData = async() => {

        try { 

            this.setState({ isLoading: true });
            
            const response = await api.get(`/item/${this.state.cd_tema}`);
            
            console.log(response)
            if( response.data !== undefined ){

                let dados = response.data.data;

                this.setState({ 
                    isLoading: false,
                    data: dados,
                    totalReg: dados.length
                });
      
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.."});
            }
      
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    selectItem = (item) => {
        
        this.setState({
            cd_item: item.cd_item,
            cd_subtopico: item.cd_subtopico,
            id_item_ordem: item.id_item_ordem,
            ds_item_nota: item.ds_item_nota,
            ds_item_img64: item.ds_item_img64,
            ds_item_tabela: item.ds_item_tabela,
            ds_item_title: item.ds_item_title,
            ds_item_descricao: item.ds_item_descricao,
        }, () => {
            /* Display Topico */
            this.state.options.map((item,i) => {
                if( item.id === this.state.cd_subtopico )
                    return this.setState({ displaySubtopico: item.title })
            })  
        })
    }

    clearSelectItem = () => {
        this.setState({
            submittedForm: false,
            cd_item: '',
            cd_subtopico: '', 
            id_item_ordem: '', 
            ds_item_nota: '', 
            ds_item_img64: '', 
            ds_item_tabela: '', 
            ds_item_title: '', 
            ds_item_descricao: '',
        })
    }

    renderItem = (item) => {
        return (
            <tr key={item.cd_item}>
                <td className="tdClickable" onClick={() => this.confirmRemoveItem(item)}>
                    <div className="deletar"><i className="mdi mdi-24px mdi-delete-circle" style={{color:'#f2545b'}}></i></div>
                </td>
                <td className="tdClickable" onClick={() => this.selectItem(item)}>
                    <i className="mdi mdi-24px mdi-pencil-circle"></i>
                </td>
                
                <td className="tdColumn">{ item.id_item_ordem }</td>
                <td className="tdColumn">{ item.ds_item_title }</td>
                <td className="tdColumn">{ item.ds_item_descricao }</td>
            </tr>
        )
    }

    renderItems = () => {
        const { data, currentPage, regperpage } = this.state;
        
        let regfim = currentPage * regperpage;
        let regini = regfim - regperpage;
        
        if( data.length > 0 ){
            return data.map((item, index) => {
                if (index >= regini && index < regfim)
                    return this.renderItem(item);
            })  
        } else {
            return (
                <tr>
                    <td colSpan="5">Nenhum registro encontrado</td>
                </tr>
            )
        }
    }

    validation = () => {
        const { ds_item_title, id_item_ordem, cd_subtopico } = this.state;

        if (ds_item_title === '' || id_item_ordem === '' || cd_subtopico === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ 
            submittedForm: true, 
        });
        
        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
        
        try { 

            var cd_subtopico  = this.state.cd_subtopico;
            
            
            if( this.state.cd_item != '' ){
                var response = await api.put(`/item/${cd_subtopico}/${this.state.cd_item}`,this.state);
            } else {
                var response = await api.post(`/item/${cd_subtopico}`,this.state);
            }
            console.log(response.data.errors)
            if( response.data.errors === "" ){    
                if (this.state.cd_item != '') {
                    toast.success('Registro editado com sucesso', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Registro inserido com sucesso', { autoClose: 3000, toastId: 1 });
                }

                this.fetchData();
                this.clearSelectItem();
            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    confirmRemoveItem = ({ cd_item }) => {
        this.setState({ 
            visibleModal: !this.state.visibleModal,
            itemDelValue: cd_item, 
        })
    }

    removeItem = (id) => {
        console.log(id)
        this.toggleModal()
    }

    toggleModal = () => {
        this.setState({ visibleModal: !this.state.visibleModal })
    }   

    toggleModalPreview = () => {
        this.setState({ visibleModalPreview: !this.state.visibleModalPreview })
    }   

    toggleImg = () => {
        this.setState(prevState => ({ popoverOpenImg: !prevState.popoverOpenImg }));
    };

    sendImage = (files) => {
        if (files.base64 !== '') {
            this.setState({
                ds_item_img64 : files.base64
            });
        }
    }

    clearImg = () => {
        
        this.setState({
            ds_item_img64: ''
        }, () => console.log(this.state));
    }

    returnImg = (imagem) => {
        
        if (imagem === undefined || imagem === null)
            imagem = '';

        const { popoverOpenImg } = this.state;
        return (imagem !== '') ? (
            <div style={{ width: '100%' }}>
                <Button className="btn btnSalvarConteudo btn-primary" id="PopoverClick" type="button" style={{ color: 'white', float: 'left' }}>
                    Ver imagem
                </Button>
                <Button className="btn btnSalvarConteudo btn-danger" onClick={this.clearImg}  type="button" style={{ color: 'white', float: 'left', marginLeft: 10 }}>
                    Deletar imagem
                </Button>
                <Popover
                placement="right"
                isOpen={popoverOpenImg}
                target="PopoverClick"
                toggle={this.toggleImg}
                dir="right"
                >
                    <PopoverHeader>
                        Visualizar Imagem
                        <div style={{ float: 'right', cursor: 'pointer', marginTop: -5 }} onClick={this.toggleImg}>
                            <i className="mdi mdi-24px mdi-close"></i>
                        </div>
                    </PopoverHeader>
                    <PopoverBody>
                        <img src={`${imagem}`} style={{ width: '100%', height: '100%' }} alt="Imagem enviada" />
                    </PopoverBody>
                </Popover>
            </div>
        ) : (null)
    }

    selectTab = (index) => {
        switch (index){
            case 0: this.props.history.push(`/tema/form/${this.state.cd_tema}`); break;
            case 1: this.props.history.push(`/topico/${this.state.cd_tema}`); break;
            case 2: this.props.history.push(`/subtopico/${this.state.cd_tema}`); break;
            case 3: this.props.history.push(`/itemsubtopico/${this.state.cd_tema}`); break;
            case 4: this.props.history.push(`/crianca/${this.state.cd_tema}`); break;
        }
    }
    
    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    render(){

        const { visibleModal, visibleModalPreview, itemDelValue, tabs, ds_item_img64, submittedForm } = this.state;
        const img64 = (ds_item_img64 === undefined || ds_item_img64 === null) ? '' : ds_item_img64;

        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>Prescrições</h2>
                                        </div>

                                        <div className="float-right">
                                            <Button type='button' className="btn-primary btn btn-md m-b-15 ml-2 mr-2" onClick={this.toggleModalPreview}>
                                                <i className="mdi mdi-tree-menu"></i> Preview
                                            </Button>
                                        </div>
                                        
                                        <div className="float-right">
                                            <Link to={`/tema/form/`} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Novo Tema
                                            </Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Modal isOpen={visibleModalPreview} size='lg' toggle={this.toggleModalPreview}>
                                            <ModalHeader toggle={this.toggleModalPreview}>Preview</ModalHeader>
                                            <ModalBody style={{ minHeight: 300, maxHeight: 500, overflow: 'auto' }}>
                                                <Preview cd_tema={this.state.cd_tema} />
                                            </ModalBody>
                                        </Modal>
                                        <Modal isOpen={visibleModal} toggle={this.toggleModal}>
                                            <ModalHeader toggle={this.toggleModal}>Confirmação</ModalHeader>
                                            <ModalBody>
                                            Tem certeza que deseja excluir este registro?
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="danger" onClick={() => this.removeItem(itemDelValue)}>Sim, com certeza</Button>
                                                <Button color="primary" onClick={this.toggleModal}>Não</Button>
                                            </ModalFooter>
                                        </Modal>
                                        <Tabs 
                                            fnCallback={this.selectTab}
                                            selected={3}
                                            campos={tabs}
                                        />
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_item_title">Título:</label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_item_title" 
                                                        className="form-control"
                                                        value={this.state.ds_item_title}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.ds_item_title === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_item_descricao">Descrição:</label>
                                                    <textarea 
                                                        name="ds_item_descricao" 
                                                        style={{ height: 120, verticalAlign: 'top' }}
                                                        className="form-control customTextarea" 
                                                        value={this.state.ds_item_descricao}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_item_nota">Nota:</label>
                                                    <textarea 
                                                        name="ds_item_nota" 
                                                        style={{ height: 120, verticalAlign: 'top' }}
                                                        className="form-control customTextarea" 
                                                        value={this.state.ds_item_nota}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_item_tabela">Tabela:</label>
                                                    <FroalaEditorComponent tag='textarea' model={this.state.ds_item_tabela} onModelChange={(value) =>{
                                                        this.setState({ds_item_tabela: value});
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="cd_subtopico">Subtópico:<span className="danger">*</span></label>
                                                    <select
                                                        name="cd_subtopico" 
                                                        placeholder={this.state.displaySubtopico || 'Selecione...'} 
                                                        value={this.state.cd_subtopico}
                                                        onChange={this.handleInputChange} 
                                                        className="form-control" 
                                                    >
                                                        { 
                                                            this.state.options.map((item) => {
                                                                return (
                                                                    <option value={item.id}>{item.title}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    { submittedForm && this.state.cd_subtopico === '' && this.requiredField()}
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label className="font-secondary">Ordem:</label>
                                                    <input 
                                                        type="text" 
                                                        name="id_item_ordem" 
                                                        className="form-control" 
                                                        value={this.state.id_item_ordem}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.id_item_ordem === '' && this.requiredField()}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>Imagem:</label>
                                                    
                                                    <div className="input-group mb-6">
                                                        { img64 !== '' && this.returnImg(img64) }
                                                        { img64 === '' &&
                                                            <div style={{ marginTop: 5 }}>
                                                                <FileBase64
                                                                    onDone={this.sendImage.bind(this)}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group float-left">
                                                <div onClick={this.clearSelectItem} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                Novo
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                        <div className="table-responsive m-t-20">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th style={{ width: 10 }}></th>
                                                    <th style={{ width: 10 }}></th>
                                                    <th>Ordem</th>
                                                    <th>Título do Item</th>
                                                    <th>Descrição</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    { this.renderItems() }
                                                </tbody>
                                            </table>
                                            <Pagination
                                                currentPage={this.state.currentPage}
                                                totalPages={this.state.totalReg}
                                                totalSize={this.state.totalReg}
                                                sizePerPage={this.state.regperpage}
                                                changeCurrentPage={this.changeCurrentPage}
                                                theme="bootstrap"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}