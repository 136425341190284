import React from 'react';
import { Spinner } from 'reactstrap';

export default function components({ isLoading }) {
    
    return isLoading ? (
        <div style={{ position: 'fixed', zIndex: 9, width: '100%', height: window.innerHeight, backgroundColor: 'rgba(255, 255, 255, 0.3)'}}>
            <div style={{ position: 'absolute', zIndex: 10, top: '50%', left: '50%' }}>                         
                <Spinner style={{ width: '2rem', height: '2rem', color: '#41A9D0' }} />
            </div>
        </div>
    ) : (
        <div />
    )
}
