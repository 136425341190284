import React, { Component } from 'react'

import api from '../../services';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/pt-br';
import IconPicker from 'react-icon-picker';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';

toast.configure();

const iconesCategorias = [
    'fa-solid fa-heart',
    'fa-solid fa-plus',
    'fa-solid fa-droplet',
    'fa-solid fa-eye',
    'fa-solid fa-flask',
    'fa-solid fa-stethoscope',
    'fa-solid fa-notes-medical',
    'fa-solid fa-user-nurse',
    'fa-solid fa-vials',
    'fa-solid fa-brain',
    'fa-solid fa-star-of-life',
    'fa-solid fa-hospital',
    'fa-solid fa-virus',
    'fa-solid fa-joint',
    'fa-solid fa-receipt',
    'fa-solid fa-viruses',
    'fa-solid fa-tablets',
    'fa-solid fa-x-ray',
    'fa-solid fa-wheelchair-move',
    'fa-solid fa-wheelchair',
    'fa-solid fa-weight-scale',
    'fa-solid fa-virus-slash',
    'fa-solid fa-virus-covid-slash',
    'fa-solid fa-virus-covid',
    'fa-solid fa-vial-virus',
    'fa-solid fa-vial-circle-check',
    'fa-solid fa-vial',
    'fa-solid fa-user-doctor',
    'fa-solid fa-truck-medical',
    'fa-solid fa-truck-droplet',
    'fa-solid fa-tooth',
    'fa-solid fa-thermometer',
    'fa-solid fa-teeth-open',
    'fa-solid fa-teeth',
    'fa-solid fa-syringe',
    'fa-solid fa-suitcase-medical',
    'fa-solid fa-staff-snake',
    'fa-solid fa-square-virus',
    'fa-solid fa-square-plus',
    'fa-solid fa-square-h',
    'fa-solid fa-smoking',
    'fa-solid fa-skull-crossbones',
    'fa-solid fa-skull',
    'fa-solid fa-shield-virus',
    'fa-solid fa-radiation',
    'fa-solid fa-pump-medical',
    'fa-solid fa-prescription-bottle-medical',
    'fa-solid fa-prescription-bottle',
    'fa-solid fa-prescription',
    'fa-solid fa-poop',
    'fa-solid fa-pills',
    'fa-solid fa-person-half-dress',
    'fa-solid fa-person-dots-from-line',
    'fa-solid fa-person-cane',
    'fa-solid fa-person-breastfeeding',
    'fa-solid fa-pager',
    'fa-solid fa-mortar-pestle',
    'fa-solid fa-microscope',
    'fa-solid fa-mask-ventilator',
    'fa-solid fa-mask-face',
    'fa-solid fa-lungs-virus',
    'fa-solid fa-lungs',
    'fa-solid fa-laptop-medical',
    'fa-solid fa-kit-medical',
    'fa-solid fa-id-card-clip',
    'fa-solid fa-house-medical',
    'fa-solid fa-house-chimney-medical',
    'fa-solid fa-hospital-user',
    'fa-solid fa-heart-pulse',
    'fa-solid fa-heart-circle-xmark',
    'fa-solid fa-heart-circle-plus',
    'fa-solid fa-heart-circle-minus',
    'fa-solid fa-heart-circle-exclamation',
    'fa-solid fa-heart-circle-check',
    'fa-solid fa-heart-circle-bolt',
    'fa-solid fa-head-side-virus',
    'fa-solid fa-head-side-mask',
    'fa-solid fa-head-side-cough-slash',
    'fa-solid fa-head-side-cough',
    'fa-solid fa-hand-holding-medical',
    'fa-solid fa-hand-holding-droplet',
    'fa-solid fa-hand-dots',
    'fa-solid fa-flask-vial',
    'fa-solid fa-fire-flame-simple',
    'fa-solid fa-file-waveform',
    'fa-solid fa-file-prescription',
    'fa-solid fa-file-medical',
    'fa-solid fa-eye-dropper',
    'fa-solid fa-droplet-slash',
    'fa-solid fa-dna',
    'fa-solid fa-disease',
    'fa-solid fa-crutch',
    'fa-solid fa-comment-medical',
    'fa-solid fa-clock-rotate-left',
    'fa-solid fa-clipboard-user',
    'fa-solid fa-circle-radiation',
    'fa-solid fa-circle-h',
    'fa-solid fa-capsules',
    'fa-solid fa-cannabis',
    'fa-solid fa-briefcase-medical',
    'fa-solid fa-book-medical',
    'fa-solid fa-bong',
    'fa-solid fa-bone',
    'fa-solid fa-biohazard',
    'fa-solid fa-bed-pulse',
    'fa-solid fa-bandage',
    'fa-solid fa-ban-smoking',
    'fa-solid fa-bacterium',
];
export default class CategoriaMedicamentoForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',
            cd_categoriamedicamento: '',
            ds_categoriamedicamento: '',
            ds_categoriamedicamento_icone: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();

        const target = event.target;
        const name = target.name;
        var value2 = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [name]: value2
        });
    }

    componentWillMount() {

        const par = this.props.match.params;

        if (par.id !== undefined) {
            this.setState({
                cd_categoriamedicamento: par.id
            }, () => {
                this.consultaAPI();
            });
        } else {
            this.consultaAPI();
        }
    }

    consultaAPI = async (act) => {

        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {

            this.setState({ isLoading: true });

            if (act === undefined) {
                var response = await api.get(`/categoriamedicamento/${this.state.cd_categoriamedicamento}`);
            } else {
                var response = null;
            }

            Promise.all([
                response,
            ]).then((values) => {

                this.setState({
                    isLoading: false,
                })

                let promisse1 = values[0];
                /**
                 * Segunda Promisse - Combo Tipo usuario
                */
                if (promisse1 !== undefined && promisse1.data !== null) {

                    let dados = promisse1.data.data;

                    this.setState({
                        cd_categoriamedicamento: dados.cd_categoriamedicamento || '',
                        ds_categoriamedicamento: dados.ds_categoriamedicamento || '',
                        ds_categoriamedicamento_icone: dados.ds_categoriamedicamento_icone || '',
                    }, () => {
                        console.log('state ', this.state)
                    });
                }
            });

        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    validation = () => {
        const { ds_categoriamedicamento } = this.state;

        if (ds_categoriamedicamento === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({
            submittedForm: true,
        });

        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {
            if (this.state.cd_categoriamedicamento != '') {
                var response = await api.put(`/categoriamedicamento/${this.state.cd_categoriamedicamento}`, this.state);
            } else {
                var response = await api.post(`/categoriamedicamento/`, this.state);
            }

            console.log(response)

            if (response.data.errors === "") {
                if (this.state.cd_categoriamedicamento != '') {
                    toast.success('Categoria do medicamento editada com sucesso!', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Categoria do medicamento realizada com sucesso!', { autoClose: 3000, toastId: 1 });
                }
                this.voltar();
            } else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }

        } catch (resp) {
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    voltar = () => {
        this.props.history.push('/categoriamedicamento');
    }

    render() {
        const { submittedForm, isLoading } = this.state;

        return (
            <div style={{ width: '100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>{this.state.cd_categoriamedicamento === '' ? 'Nova Categoria de Medicamento' : 'Categorias de Medicamentos'}</h2>
                                        </div>

                                        <div className="float-right">
                                            <Link to={`/categoriamedicamento/form/`} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Nova Categoria de Medicamento
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row">
                                                <div className='form-group col-md-12'>
                                                    <label htmlFor="">Ícone:</label>
                                                    <div style={{ display: 'block' }}>
                                                        {this.state.ds_categoriamedicamento_icone !== '' &&
                                                            <IconPicker
                                                                icons={iconesCategorias}
                                                                defaultValue={this.state.ds_categoriamedicamento_icone}
                                                                className="custom-icon-picker"
                                                                onChange={(icon) => {
                                                                    this.setState({
                                                                        ds_categoriamedicamento_icone: icon
                                                                    })
                                                                }}
                                                            />
                                                        }

                                                        {(this.state.cd_categoriamedicamento !== 0 && (this.state.ds_categoriamedicamento_icone === '')) &&
                                                            <IconPicker
                                                                icons={iconesCategorias}
                                                                defaultValue={this.state.ds_categoriamedicamento_icone}
                                                                onChange={(icon) => {
                                                                    this.setState({
                                                                        ds_categoriamedicamento_icone: icon
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_categoriamedicamento">Descrição:</label>
                                                    <input
                                                        type="text"
                                                        name="ds_categoriamedicamento"
                                                        className="form-control"
                                                        value={this.state.ds_categoriamedicamento}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {submittedForm && this.state.ds_categoriamedicamento === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                    Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}