import React, { Component } from 'react';
import propTypes from 'prop-types';

class Tabs extends Component{
    state = {
        tabs: this.props.campos,
        tabActive: this.props.selected || 0
    }

    selectTab = (index) => {
        this.setState({
            tabActive: index
        }, () => {
            this.props.fnCallback(index);
        });
    }

    renderItem = (item, index) => {
        return (
            <li className="nav-item" key={index}>
                <div 
                    className={ this.state.tabActive === index ? 'nav-link active' : 'nav-link' } 
                    id="geral-tab2"
                    href="#"
                    role="tab" 
                    aria-controls="geral" 
                    aria-selected="true"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.selectTab(index)}
                >
                    { item }
                </div>
            </li>
        )
    }

    renderTabs = () => {
        const { tabs } = this.state;
        
        return ( tabs !== null ) ?
            tabs.map((item, index) => {
                return this.renderItem(item, index)
            })
        : null;
    }

    render () {
        const { tabs } = this.state;
        return (
            <div>
                { tabs.length > 0 &&
                    <ul className="nav nav-tabs" id="myTab2" style={{ fontWeight:500, color: '#8492a6' }} role="tablist">
                        { this.renderTabs() }
                    </ul>
                }
            </div>
        )
    }
} 

Tabs.propTypes = {
    fnCallback: propTypes.func
};


export default Tabs;

