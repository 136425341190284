import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../includes/Header';
import Inicio from '../../includes/Inicio';
import Sidemenu from '../../includes/Sidemenu';

import api from '../../services';

class Main extends Component {
    state = {
        isLoading: true,
        qtdClientes: 0,
        qtdProspects: 0,
        qtdVendas: 0,
        qtdTemas: 0,
    }

    componentWillMount() {
        const cd_usuario = localStorage.getItem('@guiabasico/cd_usuario');


        if (cd_usuario !== null && cd_usuario !== undefined) {
            this.consultaAPI();
        } else {
            this.props.history.push('./');
        }

    }

    consultaAPI = async (act) => {

        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {

            var response2 = await api.get(`/dashboard`);

            Promise.all([
                response2,
            ]).then((values) => {

                this.setState({
                    isLoading: false,
                })

                let promisse2 = values[0] || null;
                /**
                 * Segunda Promisse - Combo Tipo usuario
                */

                if (promisse2 !== undefined && promisse2 !== null) {

                    let dados = promisse2.data.data || '';

                    this.setState({
                        qtdClientes: dados.qtdCliente,
                        qtdProspects: dados.qtdProspect,
                        qtdVendas: dados.qtdVenda,
                        qtdTemas: dados.qtdTema,
                    });
                }
            });

        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    render() {
        const { qtdClientes, qtdProspects, qtdVendas, qtdTemas } = this.state;
        document.body.className = 'sidebar-pinned pace-done';
        return (
            <div>
                <Sidemenu />
                <main className="admin-main">
                    <Header />
                    <Inicio
                        qtdClientes={qtdClientes}
                        qtdProspects={qtdProspects}
                        qtdVendas={qtdVendas}
                        qtdTemas={qtdTemas}
                    />
                </main>
            </div>
        )
    }
}

export default Main;