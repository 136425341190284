import React, { Component } from 'react'

import api from '../../services';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';
import Tabs from '../../components/Tabs';
import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';

toast.configure();

export default class Cliente extends Component{
    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',
            tabs: ['Cliente', 'Celulares', 'Acesso Web'],

            cd_cliente: '', 
            nm_cliente: '', 
            ds_cliente_cpf: '', 
            ds_cliente_celular: '', 
            cd_estado: '', 
            ds_cliente_email: '',
            ds_cliente_cidade: '',
            ds_cliente_bairro: '',
            ds_cliente_logradouro: '',
            ds_cliente_num: '',
            ds_cliente_cep: '',

            optionsEstado: [],
            displayEstado: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        
        const target = event.target;
        const name = target.name;
        var value2 = target.type === 'checkbox' ? target.checked : target.value;
        
        this.setState({
            [name]: value2
        });
    }

    componentWillMount(){

        const par = this.props.match.params;
        
        if (par.id !== undefined) {
            this.setState({
                cd_cliente: par.id
            }, () => {
                this.consultaAPI();
            });
        } else {
            this.consultaAPI(1);
        }
    }

    consultaAPI = async(act) => {
        
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
    
        try { 

            this.setState({ isLoading: true });

            if( act === undefined ){
                var response = await api.get(`/cliente/${this.state.cd_cliente}`);
            } else {
                var response = null;
            }
            
            var response2 = await api.get(`/comboEstado/`);
            console.log(response2)
            
            Promise.all([
                response,
                response2
            ]).then((values) => {
                
                this.setState({ 
                    isLoading: false,
                })

                let promisse1 = values[0] || null;
                let promisse2 = values[1] || null;
                /**
                 * Segunda Promisse - Combo Tipo usuario
                */
                if (promisse1 !== null) {
                
                    let dados = promisse1.data.data;
                    
                    this.setState({ 
                        nm_cliente: dados.nm_cliente, 
                        ds_cliente_cpf: dados.ds_cliente_cpf, 
                        ds_cliente_celular: dados.ds_cliente_celular, 
                        cd_estado: dados.cd_estado,
                        ds_cliente_email: dados.ds_cliente_email,
                        ds_cliente_cidade: dados.ds_cliente_cidade,
                        ds_cliente_bairro: dados.ds_cliente_bairro,
                        ds_cliente_logradouro: dados.ds_cliente_logradouro,
                        ds_cliente_num: dados.ds_cliente_num,
                        ds_cliente_cep: dados.ds_cliente_cep,
                    }, () => {
                        console.log('state ',this.state)
                    });
                } 

                if (promisse2 !== null) {
                
                    const p = promisse2.data.data || [];

                    p.unshift({
                        id: '',
                        title: 'Selecione...'
                    })

                    this.setState({
                        optionsEstado: p
                    }); 
                } 
            });
        
        } catch (response) { 
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    consultaCEP = async() => {
        if (this.state.cep === '') {
            return false;
        }

        try { 

            this.setState({ isLoading: true });
            
            var response = await api.get(`https://viacep.com.br/ws/${this.state.ds_cliente_cep}/json/`);
            
            Promise.all([
                response,
            ]).then((values) => {
                
                this.setState({ 
                    isLoading: false,
                })

                let promisse1 = values[0] || null;
                
                if (promisse1.data !== null) {
                
                    let dados = promisse1.data;
                    
                    this.setState({ 
                        ds_cliente_cidade: dados.localidade,
                        ds_cliente_bairro: dados.bairro,
                        ds_cliente_logradouro: dados.logradouro,
                        cd_estado: this.setUF(dados.uf),
                    }, () => {
                        console.log('state ',this.state)
                    });
                } 
            });
        
        } catch (response) { 
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    setUF = (uf) => {
        return this.state.optionsEstado
            .filter((item) => item.sigla === uf)
            .map(e => {
                const { id } = e;
                return id;
            });
    }

    validation = () => {
        const { nm_cliente, ds_cliente_email, ds_cliente_celular  } = this.state;
        //alert(nm_cliente+''+ds_cliente_email+''+ds_cliente_celular)
        if (nm_cliente === '' || ds_cliente_email === ''|| ds_cliente_celular === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ 
            submittedForm: true, 
        });
        
        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
        
        try { 
            console.log(this.state)
            if( this.state.cd_cliente != '' ){
                var response = await api.put(`/cliente/${this.state.cd_cliente}`,this.state);
            } else {
                var response = await api.post(`/cliente/`,this.state);
            }

            console.log(response)
            
            if( response.data.errors === "" ){    
                if (this.state.cd_cliente != '') {
                    toast.success('Cliente editado com sucesso!', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Cliente inserido com sucesso!', { autoClose: 3000, toastId: 1 });
                }

                this.voltar();

            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }
    
    selectTab = (index) => {
        switch (index){
            case 0: this.props.history.push(`/cliente/form/${this.state.cd_cliente}`); break;
            case 1: this.props.history.push(`/clientecelular/${this.state.cd_cliente}`); break;
            case 2: this.props.history.push(`/acessowebcliente/${this.state.cd_cliente}`); break;
        }
    }

    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    voltar = () => {
        this.props.history.push('/cliente');
    }

    render(){
        const { submittedForm, isLoading, tabs } = this.state;

        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>{ this.state.cd_cliente === '' ? 'Novo Cliente' : 'Cliente'}</h2>
                                        </div>
                                        
                                        <div className="float-right">
                                            <Link to={`/cliente/form/`} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Novo Cliente
                                            </Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Tabs 
                                            fnCallback={this.selectTab}
                                            selected={0}
                                            campos={tabs}
                                        />
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="nm_cliente">Nome:</label>
                                                    <input 
                                                        type="text" 
                                                        name="nm_cliente" 
                                                        className="form-control"
                                                        value={this.state.nm_cliente}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.nm_cliente === '' && this.requiredField()}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_cliente_cpf">CPF:</label>
                                                    <InputMask
                                                        mask="999.999.999-99"
                                                        className="form-control"
                                                        name="ds_cliente_cpf"
                                                        value={this.state.ds_cliente_cpf}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_cliente_celular">Celular:</label>
                                                    
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        className="form-control"
                                                        name="ds_cliente_celular"
                                                        value={this.state.ds_cliente_celular}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.ds_cliente_celular === '' && this.requiredField()}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_cliente_email">E-mail:</label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_cliente_email" 
                                                        className="form-control"
                                                        value={this.state.ds_cliente_email}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.ds_cliente_email === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_cliente_cep">CEP:</label>
                                                    
                                                    <InputMask
                                                        mask="99999-999"
                                                        className="form-control"
                                                        name="ds_cliente_cep"
                                                        value={this.state.ds_cliente_cep}
                                                        onChange={this.handleInputChange} 
                                                        onBlur={this.consultaCEP}
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_cliente_logradouro">Endereço:</label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_cliente_logradouro" 
                                                        className="form-control"
                                                        value={this.state.ds_cliente_logradouro}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_cliente_num">Número:</label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_cliente_num" 
                                                        className="form-control"
                                                        value={this.state.ds_cliente_num}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_cliente_bairro">Bairro:</label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_cliente_bairro" 
                                                        className="form-control"
                                                        value={this.state.ds_cliente_bairro}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="cd_estado">Estado:</label>
                                                    <select
                                                        name="cd_estado" 
                                                        placeholder={this.state.displayEstado || 'Selecione...'} 
                                                        value={this.state.cd_estado}
                                                        onChange={this.handleInputChange} 
                                                        className="form-control" 
                                                    >
                                                        { 
                                                            this.state.optionsEstado.map((item) => {
                                                                return (
                                                                    <option value={item.id}>{item.title}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_cliente_cidade">Cidade:</label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_cliente_cidade" 
                                                        className="form-control"
                                                        value={this.state.ds_cliente_cidade}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                            </div>
                                            
                                            
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}