import React, { Component } from 'react'

import api from '../../services';
import Pagination from "react-pagination-js";
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody, PopoverHeader,  } from 'reactstrap';
import FileBase64 from 'react-file-base64';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Tabs from '../../components/Tabs';
import Preview from '../../components/Preview';

toast.configure();

export default class Tema extends Component{

    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            visibleModalPreview: false,
            tooltip2: '',
            data: [],
            
            cd_tema: '', 
            ds_tema: '', 
            ds_tema_sintoma: '', 
            
            tabs: ['Temas', 'Tópicos', 'Subtópicos', 'Pontos','Criança'],
            itemDelValue: '', 
            itemDelDisplay: '', 
            filteredData: null,
            selectedItem: null,
            regperpage: 10,
            totalReg: 0,
            totalPages: 1,
            regini: 0,
            regfim: 0,
            currentPage: 1,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    componentWillMount(){
        const par = this.props.match.params;
        
        if (par.id !== undefined) {
            this.setState({
                cd_tema: par.id
            }, () => {
                this.consultaAPI();
            });
        }
    }
    
    consultaAPI = async(act) => {
        
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
    
        try { 

            this.setState({ isLoading: true });
            
            var response2 = await api.get(`/tema/${this.state.cd_tema}`);
            
            Promise.all([
                response2
            ]).then((values) => {
                
                this.setState({ 
                    isLoading: false,
                })

                let promisse2 = values[0];
                /**
                 * Segunda Promisse - Combo Tipo usuario
                */
                if (promisse2 !== undefined && promisse2.data !== null) {
                
                    let dados = promisse2.data.data;
                    
                    this.setState({ 
                        ds_tema: dados.ds_tema, 
                        ds_tema_sintoma: dados.ds_tema_sintoma, 
                    }, () => {
                        console.log('state ',this.state)
                    });
                } 
            });
        
        } catch (response) { 
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    validation = () => {
        const { ds_tema } = this.state;
        
        if (ds_tema === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        
        event.preventDefault();

        this.setState({ 
            submittedForm: true, 
        });
        
        if (!this.validation()) {
            return false;
        }

        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {

            if( this.state.cd_tema != '' ){
                var response = await api.put(`/tema/${this.state.cd_tema}`,this.state);
            } else {
                var response = await api.post(`/tema/`,this.state);
            }
            
            if( response.data.errors === "" ){    
                if (this.state.cd_funcaosistema != '') {
                    toast.success('Registro editado com sucesso', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Registro inserido com sucesso', { autoClose: 3000, toastId: 1 });
                }

                this.voltar();
            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    removeItem = async (id) => {
        try { 

            const response = await api.delete(`/topico/${id}`);
            console.log(response)
            if( response.data.errors === "" ){
                this.fetchData();
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.."});
            }

            this.setState({ visibleModal: false })
      
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    toggleModal = () => {
        this.setState({ visibleModal: !this.state.visibleModal })
    }   

    toggleModalPreview = () => {
        this.setState({ visibleModalPreview: !this.state.visibleModalPreview })
    }   

    toggleImg = () => {
        this.setState(prevState => ({ popoverOpenImg: !prevState.popoverOpenImg }));
    };

    sendImage = (files) => {
        if (files.base64 !== '') {
            this.setState({
                ds_tema_img64 : files.base64
            });
        }
    }

    clearImg = () => {
        
        this.setState({
            ds_tema_img64: ''
        }, () => console.log(this.state));
    }

    returnImg = (imagem) => {
        
        if (imagem === undefined || imagem === null)
            imagem = '';

        const { popoverOpenImg } = this.state;
        return (imagem !== '') ? (
            <div style={{ width: '100%' }}>
                <Button className="btn btnSalvarConteudo btn-primary" id="PopoverClick" type="button" style={{ color: 'white', float: 'left' }}>
                    Ver imagem
                </Button>
                <Button className="btn btnSalvarConteudo btn-danger" onClick={this.clearImg}  type="button" style={{ color: 'white', float: 'left', marginLeft: 10 }}>
                    Deletar imagem
                </Button>
                <Popover
                placement="right"
                isOpen={popoverOpenImg}
                target="PopoverClick"
                toggle={this.toggleImg}
                dir="right"
                >
                    <PopoverHeader>
                        Visualizar Imagem
                        <div style={{ float: 'right', cursor: 'pointer', marginTop: -5 }} onClick={this.toggleImg}>
                            <i className="mdi mdi-24px mdi-close"></i>
                        </div>
                    </PopoverHeader>
                    <PopoverBody>
                        <img src={`${imagem}`} style={{ width: '100%', height: '100%' }} alt="Imagem enviada" />
                    </PopoverBody>
                </Popover>
            </div>
        ) : (null)
    }

    selectTab = (index) => {
        switch (index){
            case 0: this.props.history.push(`/tema/form/${this.state.cd_tema}`); break;
            case 1: this.props.history.push(`/topico/${this.state.cd_tema}`); break;
            case 2: this.props.history.push(`/subtopico/${this.state.cd_tema}`); break;
            case 3: this.props.history.push(`/itemsubtopico/${this.state.cd_tema}`); break;
            case 4: this.props.history.push(`/crianca/${this.state.cd_tema}`); break;
        }
    }

    requiredField = () =>{
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    voltar = () => {
        this.props.history.push('/tema');
    }

    render(){
        const { visibleModal, visibleModalPreview, itemDelValue, tabs, submittedForm } = this.state;
            
        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>Prescrições</h2>
                                        </div>
                                        
                                        <div className="float-right">
                                            <Button type='button' className="btn-primary btn btn-md m-b-15 ml-2 mr-2" onClick={this.toggleModalPreview}>
                                                <i className="mdi mdi-tree-menu"></i> Preview
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Modal isOpen={visibleModalPreview} size='lg' toggle={this.toggleModalPreview}>
                                            <ModalHeader toggle={this.toggleModalPreview}>Preview</ModalHeader>
                                            <ModalBody style={{ minHeight: 300, maxHeight: 500, overflow: 'auto' }}>
                                                <Preview cd_tema={this.state.cd_tema} />
                                            </ModalBody>
                                        </Modal>
                                        <Modal isOpen={visibleModal} toggle={this.toggleModal}>
                                            <ModalHeader toggle={this.toggleModal}>Confirmação</ModalHeader>
                                            <ModalBody>
                                            Tem certeza que deseja excluir este tema?
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="danger" onClick={() => this.removeItem(itemDelValue)}>Sim, com certeza</Button>
                                                <Button color="primary" onClick={this.toggleModal}>Não</Button>
                                            </ModalFooter>
                                        </Modal>
                                        { this.state.cd_tema !== '' &&
                                            <Tabs 
                                                fnCallback={this.selectTab}
                                                selected={0}
                                                campos={tabs}
                                            />
                                        }
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_tema">Título:<span className="danger">*</span></label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_tema" 
                                                        className="form-control" 
                                                        value={this.state.ds_tema}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.ds_tema === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_tema_sintoma">Sintomas:</label>
                                                    <textarea 
                                                        name="ds_tema_sintoma" 
                                                        style={{ height: 120 }}
                                                        className="form-control" 
                                                        value={this.state.ds_tema_sintoma}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                            </div>
                                            
                                            
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}