import React, { Component } from 'react'

import api from '../../services';
import Pagination from "react-pagination-js";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody, PopoverHeader, } from 'reactstrap';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Tabs from '../../components/Tabs';

toast.configure();

export default class Medicamentoindicacaoclinica extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleModal: false,
            visibleModalPreview: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',
            data: [],

            cd_medicamentovariacao: '',
            cd_medicamentoindicacaoclinica: '',
            cd_medicamentodose: '',
            qt_medicamentovariacao_inicial: '',
            qt_medicamentovariacao_final: '',
            qt_medicamentovariacao_idademin: '',
            qt_medicamentovariacao_idademax: '',
            id_medicamentovariacao_doseunica: 0,
            qt_medicamentovariacao_dosepadrao: null,
            ds_medicamentovariacao_descricao: 'Tomar {x} de {x} em {x} hora(s) durante 7 dias.',
            medicamento: [],
            optionsTopico: [],
            optionsSubtopico: [],
            optionsItem: [],
            tabs: ['Medicamento', 'Orientações', 'Indicações Clínicas', 'Variações'],
            displaySubtopico: '',
            itemDelValue: '',
            itemDelDisplay: '',
            filteredData: null,
            selectedItem: null,
            regperpage: 10,
            totalReg: 0,
            totalPages: 1,
            regini: 0,
            regfim: 0,
            currentPage: 1,
            reloadForm: false,
            optionsMedicamentoDose: [],
            displayMedicamentoDose: '',
            optionsMedicacaoIndicacaoClinica: [],
            displayMedicacaoIndicacaoClinica: '',
            ds_medicamentovariacao_complemento: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateDescricaoInicial = () => {
        const { id_medicamentovariacao_doseunica } = this.state;

        let novaDescricao = 'Tomar {x} de {x} em {x} hora(s) durante 7 dias.';
        if (id_medicamentovariacao_doseunica === '1') {
            novaDescricao = 'Tomar {x} em dose única.';
        }

        this.setState({ ds_medicamentovariacao_descricao: novaDescricao });
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            // console.log();

            if (name === 'id_medicamentovariacao_doseunica') {
                this.updateDescricaoInicial();
            }
        });
    }

    componentWillMount() {
        const par = this.props.match.params;
        if (par.id !== undefined) {
            this.setState({
                cd_medicamento: par.id
            }, () => {
                this.consultaAPI();
                this.fetchData();
            });
        }
    }

    changeCurrentPage = numPage => {
        this.setState({ currentPage: numPage });
    };

    consultaAPI = async (act) => {

        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {

            this.setState({ isLoading: true });

            var response = await api.get(`/medicamentodose/`);
            var responseIndicacoes = await api.get(`/medicamentoindicacaoclinica/${this.state.cd_medicamento}`);

            Promise.all([
                response,
                responseIndicacoes
            ]).then((values) => {

                this.setState({
                    isLoading: false,
                })

                let promisse = values[0] || null;
                let promisseIndicacaoClinica = values[1] || null;

                if (promisse !== null) {

                    let p = promisse.data.data || [];

                    p.unshift({
                        id: '',
                        title: 'Selecione...'
                    })

                    this.setState({
                        optionsMedicamentoDose: p
                    });
                }

                if (promisseIndicacaoClinica !== null) {

                    let p = promisseIndicacaoClinica.data.data || [];

                    p.unshift({
                        id: '',
                        title: 'Selecione...'
                    })

                    this.setState({
                        optionsMedicacaoIndicacaoClinica: p
                    });
                }
            });

        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    /* LISTAGEM DE DADOS */
    fetchData = async () => {

        try {

            this.setState({ isLoading: true });
            const response = await api.get(`/medicamentovariacao/${this.state.cd_medicamento}`);
            const responseMedicamento = await api.get(`/medicamento/${this.state.cd_medicamento}`);

            if (response.data !== undefined) {

                let dados = response.data.data;

                this.setState({
                    isLoading: false,
                    data: dados,
                    totalReg: dados.length
                });

                if (responseMedicamento.data !== undefined) {
                    this.setState({ medicamento: responseMedicamento.data.data });
                }

            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.." });
            }

        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    selectItem = (item) => {
        this.setState({
            cd_medicamentodose: item.cd_medicamentodose,
            cd_medicamentovariacao: item.cd_medicamentovariacao,
            cd_medicamentoindicacaoclinica: item.cd_medicamentoindicacaoclinica,
            qt_medicamentovariacao_inicial: item.qt_medicamentovariacao_inicial,
            qt_medicamentovariacao_final: item.qt_medicamentovariacao_final,
            qt_medicamentovariacao_idademin: item.qt_medicamentovariacao_idademin,
            qt_medicamentovariacao_idademax: item.qt_medicamentovariacao_idademax,
            id_medicamentovariacao_doseunica: item.id_medicamentovariacao_doseunica,
            qt_medicamentovariacao_dosepadrao: item.qt_medicamentovariacao_dosepadrao,
            ds_medicamentovariacao_descricao: item.ds_medicamentovariacao_descricao,
            ds_medicamentovariacao_complemento: item.ds_medicamentovariacao_complemento,
        });
    }

    clearSelectItem = () => {
        this.setState({
            submittedForm: false,
            cd_medicamentoindicacaoclinica: '',
            cd_medicamentodose: '',
            cd_medicamentovariacao: '',
            qt_medicamentovariacao_inicial: '',
            qt_medicamentovariacao_final: '',
            qt_medicamentovariacao_idademin: '',
            qt_medicamentovariacao_idademax: '',
            id_medicamentovariacao_doseunica: '0',
            qt_medicamentovariacao_dosepadrao: '',
            ds_medicamentovariacao_descricao: 'Tomar {x} de {x} em {x} hora(s) durante 7 dias.',
            ds_medicamentovariacao_complemento: '',
        });
    }

    renderItem = (item) => {
        return (
            <tr key={item.cd_medicamentoindicacaoclinica}>
                <td className="tdClickable" onClick={() => this.confirmRemoveItem(item)}>
                    <div className="deletar"><i className="mdi mdi-24px mdi-delete-circle" style={{ color: '#f2545b' }}></i></div>
                </td>
                <td className="tdClickable" onClick={() => this.selectItem(item)}>
                    <i className="mdi mdi-24px mdi-pencil-circle"></i>
                </td>

                <td className="tdColumn">{item.ds_medicamentoindicacaoclinica}</td>
                <td className="tdColumn">{item.id_medicamentovariacao_doseunica === 1 ? 'Sim' : 'Não'}</td>
                <td className="tdColumn">{item.ds_medicamentovariacao_descricao}</td>
                <td className="tdColumn">{item.ds_medicamentovariacao_complemento}</td>

                {this.state.medicamento?.id_medicamento_gotas === 1 && // Gotas
                    <>
                        <td>
                            {item.qt_medicamentovariacao_dosepadrao}
                        </td>
                    </>
                }

                {(this.state.medicamento?.cd_tipocalculomedicamento == 2 && this.state.medicamento?.id_medicamento_gotas === 0) && // peso
                    <>
                        <td className="tdColumn">{item.qt_medicamentovariacao_inicial}</td>
                        <td className="tdColumn">{item.qt_medicamentovariacao_final}</td>
                    </>
                }
                {this.state.medicamento?.cd_tipocalculomedicamento === 1 && this.state.medicamento?.id_medicamento_gotas == 0 && // Idade e não gotas
                    <>
                        <td className="tdColumn">{item.qt_medicamentovariacao_final}</td>
                    </>
                }

                <td className="tdColumn">{item.ds_medicamentodose}</td>
            </tr>
        )
    }

    renderItems = () => {
        const { data, currentPage, regperpage } = this.state;

        let regfim = currentPage * regperpage;
        let regini = regfim - regperpage;

        if (data.length > 0) {
            return data.map((item, index) => {
                if (index >= regini && index < regfim)
                    return this.renderItem(item);
            })
        } else {
            return (
                <tr>
                    <td colSpan="8" style={{ textAlign: 'center' }}>Nenhum registro encontrado</td>
                </tr>
            )
        }
    }

    validation = () => {
        const { ds_medicamentoindicacaoclinica } = this.state;

        if (ds_medicamentoindicacaoclinica === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            submittedForm: true,
        });

        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {

            if (this.state.cd_medicamentovariacao != '') {
                var response = await api.put(`/medicamentovariacao/${this.state.cd_medicamentovariacao}`, this.state);
            } else {
                var response = await api.post(`/medicamentovariacao/`, this.state);
            }

            if (response.data.errors === "") {
                if (this.state.cd_medicamentovariacao != '') {
                    toast.success('Registro editado com sucesso', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Registro inserido com sucesso', { autoClose: 3000, toastId: 1 });
                }

                this.fetchData();
                this.clearSelectItem();
            } else {
                toast.error(response.data.errors, { autoClose: 3000, toastId: 1 });
            }

        } catch (resp) {
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    confirmRemoveItem = ({ cd_medicamentovariacao }) => {
        this.setState({
            visibleModal: !this.state.visibleModal,
            itemDelValue: cd_medicamentovariacao,
        })
    }

    removeItem = async (id) => {
        try {

            const response = await api.delete(`/medicamentovariacao/${id}`);
            if (response.data.errors === "") {
                this.fetchData();
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.." });
            }

            this.setState({ visibleModal: false })

        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    selectTab = (index) => {
        switch (index) {
            case 0: this.props.history.push(`/medicamento/form/${this.state.cd_medicamento}`); break;
            case 1: this.props.history.push(`/medicamentoorientacao/${this.state.cd_medicamento}`); break;
            case 2: this.props.history.push(`/medicamentoindicacaoclinica/${this.state.cd_medicamento}`); break;
            case 3: this.props.history.push(`/medicamentovariacao/${this.state.cd_medicamento}`); break;
        }
    }

    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    render() {

        const { visibleModal, itemDelValue, tabs, submittedForm } = this.state;

        return (
            <div style={{ width: '100%' }}>
                <Sidemenu />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>Variações</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Modal isOpen={visibleModal} toggle={this.toggleModal}>
                                            <ModalHeader toggle={this.toggleModal}>Confirmação</ModalHeader>
                                            <ModalBody>
                                                Tem certeza que deseja excluir este registro?
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="danger" onClick={() => this.removeItem(itemDelValue)}>Sim, com certeza</Button>
                                                <Button color="primary" onClick={this.toggleModal}>Não</Button>
                                            </ModalFooter>
                                        </Modal>
                                        <Tabs
                                            fnCallback={this.selectTab}
                                            selected={3}
                                            campos={tabs}
                                        />
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="cd_medicamentoindicacaoclinica">Indicação Clínica:</label>
                                                    <select
                                                        name="cd_medicamentoindicacaoclinica"
                                                        placeholder={this.state.displayMedicacaoIndicacaoClinica || 'Selecione...'}
                                                        value={this.state.cd_medicamentoindicacaoclinica}
                                                        onChange={this.handleInputChange}
                                                        className="form-control"
                                                    >
                                                        {
                                                            this.state.optionsMedicacaoIndicacaoClinica.map((item) => {
                                                                return (
                                                                    <option value={item.cd_medicamentoindicacaoclinica}>{item.title ?? item.ds_medicamentoindicacaoclinica}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {submittedForm && this.state.cd_medicamentoindicacaoclinica === '' && this.requiredField()}
                                                </div>

                                                <div className="form-group col-md-2">
                                                    <label htmlFor="id_medicamentovariacao_doseunica">Dose Única:</label>
                                                    <select
                                                        name="id_medicamentovariacao_doseunica"
                                                        value={this.state.id_medicamentovariacao_doseunica}
                                                        onChange={this.handleInputChange}
                                                        className="form-control"
                                                    >
                                                        <option value='0'>Não</option>
                                                        <option value='1'>Sim</option>
                                                    </select>
                                                    {submittedForm && this.state.id_medicamentovariacao_doseunica === '' && this.requiredField()}
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_crianca_title">Descrição:</label>
                                                    <input
                                                        type="text"
                                                        name="ds_medicamentovariacao_descricao"
                                                        className="form-control"
                                                        value={this.state.ds_medicamentovariacao_descricao}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {submittedForm && this.state.ds_medicamentovariacao_descricao === '' && this.requiredField()}
                                                </div>

                                            </div>

                                            <div className='form-row'>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="ds_crianca_title">Complemento:</label>
                                                    <input
                                                        type="text"
                                                        name="ds_medicamentovariacao_complemento"
                                                        className="form-control"
                                                        value={this.state.ds_medicamentovariacao_complemento}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>

                                                {(this.state.medicamento?.cd_tipocalculomedicamento == 2 && this.state.medicamento?.id_medicamento_gotas === 0) &&
                                                    <div className="form-group col-md-3">
                                                        <label htmlFor="ds_crianca_title">Dosagem Inicial:</label>
                                                        <input
                                                            type="number"
                                                            name="qt_medicamentovariacao_inicial"
                                                            className="form-control"
                                                            value={this.state.qt_medicamentovariacao_inicial}
                                                            onChange={this.handleInputChange}
                                                            step="0.1"
                                                        />
                                                    </div>
                                                }

                                                {(this.state.medicamento?.id_medicamento_gotas === 0) &&
                                                    <div className="form-group col-md-3">
                                                        <label htmlFor="ds_crianca_title">Dosagem Final:</label>
                                                        <input
                                                            type="number"
                                                            name="qt_medicamentovariacao_final"
                                                            className="form-control"
                                                            value={this.state.qt_medicamentovariacao_final}
                                                            onChange={this.handleInputChange}
                                                            step="0.1"
                                                        />
                                                    </div>
                                                }

                                                {this.state.medicamento?.id_medicamento_gotas === 1 &&
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="qt_medicamentovariacao_dosepadrao">Dosagem Padrão:</label>
                                                        <input
                                                            type="number"
                                                            name="qt_medicamentovariacao_dosepadrao"
                                                            className="form-control"
                                                            value={this.state.qt_medicamentovariacao_dosepadrao}
                                                            onChange={this.handleInputChange}
                                                            step="0.1"
                                                        />
                                                    </div>
                                                }

                                                <div className="form-group col-md-3">
                                                    <label htmlFor="cd_medicamentodose">Indicador Dosagem Final:</label>
                                                    <select
                                                        name="cd_medicamentodose"
                                                        placeholder={this.state.displayMedicamentoDose || 'Selecione...'}
                                                        value={this.state.cd_medicamentodose}
                                                        onChange={this.handleInputChange}
                                                        className="form-control"
                                                    >
                                                        {
                                                            this.state.optionsMedicamentoDose.map((item) => {
                                                                return (
                                                                    <option value={item.cd_medicamentodose}>{item.title ?? item.ds_medicamentodose}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-group float-left">
                                                <div onClick={this.clearSelectItem} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                    Novo
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                        <div className="table-responsive m-t-20">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: 10 }}></th>
                                                        <th style={{ width: 10 }}></th>
                                                        <th>Indicação Clínica</th>
                                                        <th>Dose Única</th>
                                                        <th>Descrição</th>
                                                        <th>Complemento</th>
                                                        {this.state.medicamento?.id_medicamento_gotas == 1 && // Gotas
                                                            <>
                                                                <th>Dosagem Padrão</th>
                                                            </>
                                                        }

                                                        {(this.state.medicamento?.cd_tipocalculomedicamento === 2 && this.state.medicamento?.id_medicamento_gotas === 0) && // peso e não gotas
                                                            <>
                                                                <th>Dosagem Inicial</th>
                                                                <th>Dosagem Final</th>
                                                            </>
                                                        }

                                                        {this.state.medicamento?.cd_tipocalculomedicamento === 1 && this.state.medicamento?.id_medicamento_gotas == 0 && // Idade e não gotas
                                                            <>
                                                                <th>Dosagem Final</th>
                                                            </>
                                                        }

                                                        <th>Indicador Dosagem Final</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.renderItems()}
                                                </tbody>
                                            </table>
                                            <Pagination
                                                currentPage={this.state.currentPage}
                                                totalPages={this.state.totalReg}
                                                totalSize={this.state.totalReg}
                                                sizePerPage={this.state.regperpage}
                                                changeCurrentPage={this.changeCurrentPage}
                                                theme="bootstrap"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}