import React, { Component, Fragment } from "react";

import api from "../../services";
import Pagination from "react-pagination-js";

import { Link } from "react-router-dom";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";

import { MoneyFormat } from "../../utils/MoneyFormat";
import Header from "../../includes/Header";
import Sidemenu from "../../includes/Sidemenu";
import Loading from "../../components/Loading";

export default class Venda extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleModal: false,
      isLoading: false,
      popoverOpenImg: false,
      tooltip2: "",
      data: [],
      searchText: "",
      filteredData: null,
      selectedItem: null,
      regperpage: 10,
      totalReg: 0,
      totalPages: 1,
      regini: 0,
      regfim: 0,
      currentPage: 1,
      tipoModal: "delete",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.search(value);
      }
    );
  }

  componentWillMount() {
    this.fetchData();
  }

  changeCurrentPage = (numPage) => {
    this.setState({ currentPage: numPage });
  };

  /* LISTAGEM DE DADOS */
  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      const response = await api.get(`/venda`);

      console.log(response);
      if (response.data !== undefined) {
        let dados = response.data.data;

        this.setState({
          isLoading: false,
          data: dados,
          totalReg: dados.length,
        });
      } else {
        this.setState({
          isLoading: false,
          tooltip2: "Dificuldades Técnicas..",
        });
      }
    } catch (response) {
      console.log(response);
      this.setState({ isLoading: false, tooltip2: "Houve um erro" });
    }
  };

  selectItem = (item) => {
    this.props.history.push(`/venda/form/${item.cd_venda}`);
  };

  renderItem = (item) => {
    return (
      <tr key={item.cd_venda}>
        <td
          className="tdClickable"
          onClick={() => this.confirmRemoveItem(item)}
        >
          <div className="deletar">
            <i
              className="mdi mdi-24px mdi-delete-circle"
              style={{ color: "#f2545b" }}
            ></i>
          </div>
        </td>
        <td className="tdClickable" onClick={() => this.selectItem(item)}>
          <i className="mdi mdi-24px mdi-pencil-circle"></i>
        </td>
        <td
          className="tdClickable"
          onClick={() => this.confirmResetItem(item)}
          alt="Resetar Código"
          title="Resetar Código"
        >
          <i className="mdi mdi-24px mdi-restart"></i>
        </td>

        <td className="tdColumn">{item.cd_venda}</td>
        <td className="tdColumn">{item.dt_venda}</td>
        <td className="tdColumn">{item.nm_cliente}</td>
        <td className="tdColumn">{MoneyFormat(item.vl_venda || 0)}</td>
        <td className="tdColumn">{item.cd_venda_acesso}</td>
        <td className="tdColumn">
          {item.id_venda_ativa === 1 ? (
            <p style={{ color: "green" }}>ATIVO</p>
          ) : (
            "INATIVO"
          )}
        </td>
      </tr>
    );
  };

  renderItems = () => {
    const { data, filteredData, currentPage, regperpage } = this.state;

    let regfim = currentPage * regperpage;
    let regini = regfim - regperpage;

    if (data.length > 0) {
      const arr = filteredData && filteredData.length > 0 ? filteredData : data;
      return arr.map((item, index) => {
        if (index >= regini && index < regfim) return this.renderItem(item);
      });
    } else {
      return (
        <tr>
          <td colSpan="5">Nenhum registro encontrado</td>
        </tr>
      );
    }
  };

  search = (searchText) => {
    this.setState({ searchText: searchText });

    let filteredData = this.state.data.filter(function (item) {
      return item.nm_cliente.toUpperCase().includes(searchText.toUpperCase());
    });

    this.setState({ filteredData: filteredData });
  };

  confirmRemoveItem = ({ cd_venda }) => {
    this.setState({
      visibleModal: !this.state.visibleModal,
      itemDelValue: cd_venda,
    });
  };

  confirmResetItem = ({ cd_venda }) => {
    this.setState({
      visibleModal: !this.state.visibleModal,
      itemDelValue: cd_venda,
      tipoModal: "reset",
    });
  };

  removeItem = async (id) => {
    try {
      const response = await api.delete(`/venda/${id}`);
      console.log(response);
      if (response.data.errors === "") {
        this.fetchData();
      } else {
        this.setState({
          isLoading: false,
          tooltip2: "Dificuldades Técnicas..",
        });
      }

      this.setState({ visibleModal: false });
    } catch (response) {
      console.log(response);
      this.setState({ isLoading: false, tooltip2: "Houve um erro" });
    }
  };

  resetCodigo = async (id) => {
    try {
      const response = await api.get(`/resetCodigo/${id}`);
      console.log(response);

      this.fetchData();

      this.setState({
        isLoading: false,
        visibleModal: false,
        tooltip2: "Dificuldades Técnicas..",
      });
    } catch (response) {
      console.log(response);
      this.setState({ isLoading: false, tooltip2: "Houve um erro" });
    }
  };

  toggleModal = () => {
    this.setState({ visibleModal: !this.state.visibleModal });
  };

  render() {
    const { visibleModal, itemDelValue, isLoading } = this.state;

    return (
      <div style={{ width: "100%" }}>
        <Sidemenu />
        <Loading isLoading={isLoading} />
        <main className="admin-main">
          <Header />
          <div className="bg-gray-400">
            <div className="container  m-b-30">
              <div className="row">
                <div className="col-12 p-t-20 p-b-70">
                  <div className="media">
                    <div className="avatar avatar mr-3">
                      <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                    </div>
                    <div className="media-body">
                      <h2>Vendas</h2>
                    </div>

                    <div className="float-right">
                      <Link
                        to={`/venda/form/`}
                        className="btn-primary btn btn-md m-b-15 ml-2 mr-2"
                      >
                        <i className="mdi mdi-plus"></i> Nova Venda
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container  pull-up">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <Modal isOpen={visibleModal} toggle={this.toggleModal}>
                      <ModalHeader toggle={this.toggleModal}>
                        Confirmação
                      </ModalHeader>
                      {this.state.tipoModal === "delete" ? (
                        <Fragment>
                          <ModalBody>
                            Tem certeza que deseja excluir esta venda?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="danger"
                              onClick={() => this.removeItem(itemDelValue)}
                            >
                              Sim, com certeza
                            </Button>
                            <Button color="primary" onClick={this.toggleModal}>
                              Não
                            </Button>
                          </ModalFooter>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <ModalBody>Deseja resetar este código?</ModalBody>
                          <ModalFooter>
                            <Button
                              color="danger"
                              onClick={() => this.resetCodigo(itemDelValue)}
                            >
                              Sim, com certeza
                            </Button>
                            <Button color="primary" onClick={this.toggleModal}>
                              Não
                            </Button>
                          </ModalFooter>
                        </Fragment>
                      )}
                    </Modal>
                    <div className="inputSearch">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Buscar..."
                        value={this.state.searchText}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="table-responsive m-t-20">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th style={{ width: 10 }}></th>
                            <th style={{ width: 10 }}></th>
                            <th style={{ width: 10 }}></th>
                            <th>Venda</th>
                            <th>Data</th>
                            <th>Cliente</th>
                            <th>Valor</th>
                            <th>CÓDIGO PESSOAL</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderItems()}</tbody>
                      </table>
                      <Pagination
                        currentPage={this.state.currentPage}
                        totalPages={this.state.totalReg}
                        totalSize={this.state.totalReg}
                        sizePerPage={this.state.regperpage}
                        changeCurrentPage={this.changeCurrentPage}
                        theme="bootstrap"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
