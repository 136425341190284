import React, { Component } from 'react'

import api from '../../services';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';
import Tabs from '../../components/Tabs';
import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';
import { Button } from 'reactstrap';

toast.configure();

export default class MedicamentoForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',
            tabs: ['Medicamento', 'Orientações', 'Indicações Clínicas', 'Variações'],
            cd_categoriamedicamento: '',
            cd_medicamento: '',
            cd_tipocalculomedicamento: '',
            ds_medicamento: '',
            id_medicamento_gotas: 0,
            optionsCategoriaMedicamento: [],
            displayCategoriaMedicamento: '',
            optionsTipoCalculoMedicamento: [],
            displayTipoCalculoMedicamento: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateMedicamentoGotas = () => {
        const { cd_tipocalculomedicamento } = this.state;

        if (cd_tipocalculomedicamento === '1') { // é por idade..gotas não disponivel
            let id_medicamento_gotas = 0;
            this.setState({ id_medicamento_gotas: id_medicamento_gotas });
        }

    };

    updateTipoCalculo = () => {
        const { id_medicamento_gotas } = this.state;
        if (id_medicamento_gotas === '1') {
            this.setState({ cd_tipocalculomedicamento: 2 });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            if (name === 'cd_tipocalculomedicamento') {
                this.updateMedicamentoGotas();
            }
            if (name === 'id_medicamento_gotas') {
                this.updateTipoCalculo();
            }
        });
    }

    componentWillMount() {

        const par = this.props.match.params;

        if (par.id !== undefined) {
            this.setState({
                cd_medicamento: par.id
            }, () => {
                this.consultaAPI();
            });
        } else {
            this.consultaAPI(1);
        }
    }

    consultaAPI = async (act) => {

        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {

            this.setState({ isLoading: true });

            if (act === undefined) {
                var response = await api.get(`/medicamento/${this.state.cd_medicamento}`);
            } else {
                var response = null;
            }

            var response2 = await api.get(`/categoriamedicamento/`);

            var response3 = await api.get(`/tipocalculomedicamento/`);

            Promise.all([
                response,
                response2,
                response3
            ]).then((values) => {

                this.setState({
                    isLoading: false,
                })

                let promisse1 = values[0] || null;
                let promisse2 = values[1] || null;
                let promisse3 = values[2] || null;
                /**
                 * Segunda Promisse
                */
                if (promisse1 !== null) {

                    let dados = promisse1.data.data;

                    this.setState({
                        cd_medicamento: dados.cd_medicamento,
                        cd_categoriamedicamento: dados.cd_categoriamedicamento,
                        cd_tipocalculomedicamento: dados.cd_tipocalculomedicamento,
                        ds_medicamento: dados.ds_medicamento,
                        id_medicamento_gotas: dados.id_medicamento_gotas,
                    }, () => {
                    });
                }

                if (promisse2 !== null) {

                    const p = promisse2.data.data || [];

                    p.unshift({
                        id: '',
                        title: 'Selecione...'
                    })

                    this.setState({
                        optionsCategoriaMedicamento: p,
                    });
                }

                if (promisse3 !== null) {
                    const p2 = promisse3.data.data || [];

                    p2.unshift({
                        id: '',
                        title: 'Selecione...'
                    });

                    this.setState({
                        optionsTipoCalculoMedicamento: p2
                    });
                }
            });

        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    validation = () => {
        const { nm_cliente, ds_cliente_email, ds_cliente_celular } = this.state;
        //alert(nm_cliente+''+ds_cliente_email+''+ds_cliente_celular)
        if (nm_cliente === '' || ds_cliente_email === '' || ds_cliente_celular === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({
            submittedForm: true,
        });

        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {
            console.log(this.state)
            if (this.state.cd_medicamento != '') {
                var response = await api.put(`/medicamento/${this.state.cd_medicamento}`, this.state);
            } else {
                var response = await api.post(`/medicamento/`, this.state);
            }

            if (response.data.errors === "") {
                if (this.state.cd_cliente != '') {
                    toast.success('Medicamento editado com sucesso!', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Medicamento inserido com sucesso!', { autoClose: 3000, toastId: 1 });
                }

                this.voltar();

            } else {
                toast.error(response.data.errors, { autoClose: 3000, toastId: 1 });
            }

        } catch (resp) {
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    selectTab = (index) => {
        switch (index) {
            case 0: this.props.history.push(`/medicamento/form/${this.state.cd_medicamento}`); break;
            case 1: this.props.history.push(`/medicamentoorientacao/${this.state.cd_medicamento}`); break;
            case 2: this.props.history.push(`/medicamentoindicacaoclinica/${this.state.cd_medicamento}`); break;
            case 3: this.props.history.push(`/medicamentovariacao/${this.state.cd_medicamento}`); break;
        }
    }

    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    voltar = () => {
        this.props.history.push('/medicamento');
    }

    changeNovoMedicamento = () => {
        this.setState({
            cd_categoriamedicamento: '',
            cd_medicamento: '',
            cd_tipocalculomedicamento: '',
            ds_medicamento: '',
        });

        this.props.history.push(`/medicamento/form/`)
    }

    render() {
        const { submittedForm, isLoading, tabs } = this.state;

        return (
            <div style={{ width: '100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>{this.state.cd_medicamento === '' ? 'Novo Medicamento' : 'Medicamento'}</h2>
                                        </div>

                                        <div className="float-right">
                                            <Button onClick={() => this.changeNovoMedicamento()} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Novo Medicamento
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.state.cd_medicamento > 0 &&
                                            <Tabs
                                                fnCallback={this.selectTab}
                                                selected={0}
                                                campos={tabs}
                                            />
                                        }
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="nm_cliente">Nome:</label>
                                                    <input
                                                        type="text"
                                                        name="ds_medicamento"
                                                        className="form-control"
                                                        value={this.state.ds_medicamento}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {submittedForm && this.state.nm_cliente === '' && this.requiredField()}
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="cd_categoriamedicamento">Categoria do Medicamento:</label>
                                                    <select
                                                        name="cd_categoriamedicamento"
                                                        placeholder={this.state.displayCategoriaMedicamento || 'Selecione...'}
                                                        value={this.state.cd_categoriamedicamento}
                                                        onChange={this.handleInputChange}
                                                        className="form-control"
                                                    >
                                                        {
                                                            this.state.optionsCategoriaMedicamento.map((item) => {
                                                                return (
                                                                    <option value={item.cd_categoriamedicamento}>{item.title ?? item.ds_categoriamedicamento}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <label htmlFor="cd_tipocalculomedicamento">Tipo Cálculo do Medicamento:</label>
                                                    <select
                                                        name="cd_tipocalculomedicamento"
                                                        placeholder={this.state.displayTipoCalculoMedicamento || 'Selecione...'}
                                                        value={this.state.cd_tipocalculomedicamento}
                                                        onChange={this.handleInputChange}
                                                        className="form-control"
                                                    >
                                                        {
                                                            this.state.optionsTipoCalculoMedicamento.map((item) => {
                                                                return (
                                                                    <option value={item.cd_tipocalculomedicamento}>{item.title ?? item.ds_tipocalculomedicamento}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <label htmlFor="cd_categoriamedicamento">Medicamento em Gotas?</label>
                                                    <select
                                                        name="id_medicamento_gotas"
                                                        value={this.state.id_medicamento_gotas}
                                                        onChange={this.handleInputChange}
                                                        className="form-control"
                                                    >
                                                        <option value='0'>Não</option>
                                                        <option value='1'>Sim</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                    Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}