import React, { Component } from 'react'

import api from '../../services';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';

toast.configure();

export default class UsuarioForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: true,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',

            cd_usuario: '', 
            nm_usuario: '', 
            ds_usuario_login: '', 
            ds_usuario_email: '', 
            ds_usuario_senha: '', 
            ds_usuario_senha2: '', 
            cd_grupousuario: '',
            
            optionsGrupousuario: [],
            displayGrupousuario: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        
        const target = event.target;
        const name = target.name;
        var value2 = target.type === 'checkbox' ? target.checked : target.value;
        
        this.setState({
            [name]: value2
        });
    }

    componentWillMount(){

        const par = this.props.match.params;
        
        if (par.id !== undefined) {
            this.setState({
                cd_usuario: par.id
            }, () => {
                this.consultaAPI();
            });
        } else {
            this.consultaAPI(1);
        }
    }

    consultaAPI = async(act) => {
        
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
    
        try { 

            this.setState({ isLoading: true });

            if( act === undefined ){
                var response = await api.get(`/usuario/${this.state.cd_usuario}`);
            } else {
                var response = null;
            }
            
            var response3 = await api.get(`/comboGrupousuario/`);
            console.log(response3)
            
            Promise.all([
                response,
                response3,
            ]).then((values) => {
                
                this.setState({ 
                    isLoading: false,
                })

                let promisse1 = values[0];
                let promisse3 = values[1] || null;
                /**
                 * Segunda Promisse - Combo Tipo usuario
                */
               //console.log('a ',promisse3)
                if (promisse1 !== undefined && promisse1 !== null) {
                
                    let dados = promisse1.data.data || '';
                    
                    this.setState({ 
                        nm_usuario: dados.nm_usuario, 
                        ds_usuario_email: dados.ds_usuario_email, 
                        ds_usuario_login: dados.ds_usuario_login, 
                        cd_grupousuario: dados.cd_grupousuario,
                    }, () => {
                        console.log('state ',this.state)
                    });
                } 
                
                if (promisse3 !== undefined && promisse3 !== null) {
                
                    const p = promisse3.data.data || [];

                    p.unshift({
                        id: '',
                        title: 'Selecione...'
                    })
                    
                    this.setState({
                        optionsGrupousuario: p
                    }); 
                } 
            });
        
        } catch (response) { 
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    validation = () => {
        const { 
            cd_usuario, 
            ds_usuario_nome, 
            ds_usuario_login, 
            cd_grupousuario, 
            ds_usuario_senha, 
            ds_usuario_senha2, 
        } = this.state;

        var continuar = true;

        if( cd_usuario === '' ){
            if (ds_usuario_senha === '' && ds_usuario_senha2 === '') {
                continuar = false;
            }
            if (ds_usuario_senha !== ds_usuario_senha2) {
                toast.error('Senhas diferentes', { autoClose: 3000, toastId: 1 });
                continuar = false;
            }
        }

        if (ds_usuario_nome === '' || ds_usuario_login === '' || cd_grupousuario === '') {
            continuar = false;
        } 

        return continuar;
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ 
            submittedForm: true, 
        });
        
        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
        
        try { 
            console.log(this.state)
            if( this.state.cd_usuario != '' ){
                var response = await api.put(`/usuario/${this.state.cd_usuario}`,this.state);
            } else {
                var response = await api.post(`/usuario/`,this.state);
            }
            console.log(response)
            if( response.data.errors === "" ){    
                if (this.state.cd_usuario !== '') {
                    toast.success('Usuário editado com sucesso!', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Usuário inserido com sucesso!', { autoClose: 3000, toastId: 1 });
                }

                this.voltar();

            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }
    
    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    voltar = () => {
        this.props.history.push('/usuario');
    }

    render(){
        const { submittedForm, cd_usuario, isLoading } = this.state;

        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>{ this.state.cd_usuario === '' ? 'Novo Usuário' : 'Usuários'}</h2>
                                        </div>
                                        
                                        <div className="float-right">
                                            <Link to={`/usuario/form/`} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Novo Usuário
                                            </Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="nm_usuario">Nome:</label>
                                                    <input 
                                                        type="text" 
                                                        name="nm_usuario" 
                                                        className="form-control"
                                                        value={this.state.nm_usuario}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.nm_usuario === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_usuario_login">Login:</label>
                                                    
                                                    <input 
                                                        type="text" 
                                                        name="ds_usuario_login" 
                                                        className="form-control"
                                                        value={this.state.ds_usuario_login}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_usuario_email">E-mail:</label>
                                                    <input 
                                                        type="text" 
                                                        name="ds_usuario_email" 
                                                        className="form-control"
                                                        value={this.state.ds_usuario_email}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                    { submittedForm && this.state.ds_usuario_email === '' && this.requiredField()}
                                                </div>
                                            </div>
                                            
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="cd_grupousuario">Grupo:</label>
                                                    <select
                                                        name="cd_grupousuario" 
                                                        placeholder={this.state.displayGrupousuario || 'Selecione...'} 
                                                        value={this.state.cd_grupousuario}
                                                        onChange={this.handleInputChange} 
                                                        className="form-control" 
                                                    >
                                                        { 
                                                            this.state.optionsGrupousuario.map((item) => {
                                                                return (
                                                                    <option value={item.id}>{item.title}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    { submittedForm && this.state.cd_grupousuario === '' && this.requiredField()}
                                                </div>
                                                
                                            </div>
                                            { cd_usuario === '' &&
                                                <div className="form-row" >
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="ds_usuario_senha">Senha:</label>
                                                        
                                                        <input 
                                                            type="password" 
                                                            name="ds_usuario_senha" 
                                                            className="form-control"
                                                            value={this.state.ds_usuario_senha}
                                                            onChange={this.handleInputChange} 
                                                        />
                                                        { submittedForm && cd_usuario === '' && this.state.ds_usuario_senha === '' && this.requiredField()}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="ds_usuario_senha2">Repita a Senha:</label>
                                                        
                                                        <input 
                                                            type="password" 
                                                            name="ds_usuario_senha2" 
                                                            className="form-control"
                                                            value={this.state.ds_usuario_senha2}
                                                            onChange={this.handleInputChange} 
                                                        />
                                                        { submittedForm && cd_usuario === '' && this.state.ds_usuario_senha2 === '' && this.requiredField()}
                                                    </div>
                                                </div>
                                            }
                                            
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}