import React, { Component } from 'react'

import api from '../../services';
import Pagination from "react-pagination-js";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody, PopoverHeader, } from 'reactstrap';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Tabs from '../../components/Tabs';

toast.configure();

export default class Medicamentoorientacao extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleModal: false,
            visibleModalPreview: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',
            data: [],
            
            cd_medicamentoorientacao: '',
            cd_medicamento: '',
            ds_medicamentoorientacao: '',
            optionsTopico: [],
            optionsSubtopico: [],
            optionsItem: [],
            tabs: ['Medicamento', 'Orientações', 'Indicações Clínicas', 'Variações'],
            displaySubtopico: '',
            itemDelValue: '',
            itemDelDisplay: '',
            filteredData: null,
            selectedItem: null,
            regperpage: 10,
            totalReg: 0,
            totalPages: 1,
            regini: 0,
            regfim: 0,
            currentPage: 1,
            reloadForm: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            // console.log();
        });
    }

    componentWillMount() {
        const par = this.props.match.params;
        if (par.id !== undefined) {
            this.setState({
                cd_medicamento: par.id
            }, () => {
                this.consultaAPI();
                this.fetchData();
            });
        }
    }

    changeCurrentPage = numPage => {
        this.setState({ currentPage: numPage });
    };

    consultaAPI = async (act) => {

        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {

            this.setState({ isLoading: true });

            var response2 = await api.get(`/medicamentoorientacao/${this.state.cd_medicamento}`);

            Promise.all([
                response2,
            ]).then((values) => {

                this.setState({
                    isLoading: false,
                })

                let promisse = values[0];
            });

        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    /* LISTAGEM DE DADOS */
    fetchData = async () => {

        try {

            this.setState({ isLoading: true });
            const response = await api.get(`/medicamentoorientacao/${this.state.cd_medicamento}`);

            if (response.data !== undefined) {

                let dados = response.data.data;

                this.setState({
                    isLoading: false,
                    data: dados,
                    totalReg: dados.length
                });

            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.." });
            }

        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    selectItem = (item) => {
        this.setState({
            cd_medicamento: item.cd_medicamento,
            cd_medicamentoorientacao: item.cd_medicamentoorientacao,
            ds_medicamentoorientacao: item.ds_medicamentoorientacao,
        });
    }

    clearSelectItem = () => {
        this.setState({
            submittedForm: false,
            cd_medicamentoorientacao: '',
            ds_medicamentoorientacao: '',
        });
    }

    renderItem = (item) => {
        return (
            <tr key={item.cd_medicamentoorientacao}>
                <td className="tdClickable" onClick={() => this.confirmRemoveItem(item)}>
                    <div className="deletar"><i className="mdi mdi-24px mdi-delete-circle" style={{ color: '#f2545b' }}></i></div>
                </td>
                <td className="tdClickable" onClick={() => this.selectItem(item)}>
                    <i className="mdi mdi-24px mdi-pencil-circle"></i>
                </td>

                <td className="tdColumn">{item.ds_medicamentoorientacao}</td>
            </tr>
        )
    }

    renderItems = () => {
        const { data, currentPage, regperpage } = this.state;

        let regfim = currentPage * regperpage;
        let regini = regfim - regperpage;

        if (data.length > 0) {
            return data.map((item, index) => {
                if (index >= regini && index < regfim)
                    return this.renderItem(item);
            })
        } else {
            return (
                <tr>
                    <td colSpan="3">Nenhum registro encontrado</td>
                </tr>
            )
        }
    }

    validation = () => {
        const { ds_medicamentoorientacao } = this.state;

        if (ds_medicamentoorientacao === '') {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            submittedForm: true,
        });

        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

        try {

            if (this.state.cd_medicamentoorientacao != '') {
                var response = await api.put(`/medicamentoorientacao/${this.state.cd_medicamentoorientacao}`, this.state);
            } else {
                var response = await api.post(`/medicamentoorientacao/`, this.state);
            }

            if (response.data.errors === "") {
                if (this.state.cd_medicamentoorientacao != '') {
                    toast.success('Registro editado com sucesso', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Registro inserido com sucesso', { autoClose: 3000, toastId: 1 });
                }

                this.fetchData();
                this.clearSelectItem();
            } else {
                toast.error(response.data.errors, { autoClose: 3000, toastId: 1 });
            }

        } catch (resp) {
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    confirmRemoveItem = ({ cd_medicamentoorientacao }) => {
        this.setState({
            visibleModal: !this.state.visibleModal,
            itemDelValue: cd_medicamentoorientacao,
        })
    }

    removeItem = async (id) => {
        try {

            const response = await api.delete(`/medicamentoorientacao/${id}`);
            if (response.data.errors === "") {
                this.fetchData();
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.." });
            }

            this.setState({ visibleModal: false })

        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    toggleModal = () => {
        this.setState({ visibleModal: !this.state.visibleModal })
    }

    toggleModalPreview = () => {
        this.setState({ visibleModalPreview: !this.state.visibleModalPreview })
    }

    toggleImg = () => {
        this.setState(prevState => ({ popoverOpenImg: !prevState.popoverOpenImg }));
    };

    sendImage = (files) => {
        if (files.base64 !== '') {
            this.setState({
                ds_crianca_img64: files.base64
            });
        }
    }

    selectTab = (index) => {
        switch (index) {
            case 0: this.props.history.push(`/medicamento/form/${this.state.cd_medicamento}`); break;
            case 1: this.props.history.push(`/medicamentoorientacao/${this.state.cd_medicamento}`); break;
            case 2: this.props.history.push(`/medicamentoindicacaoclinica/${this.state.cd_medicamento}`); break;
            case 3: this.props.history.push(`/medicamentovariacao/${this.state.cd_medicamento}`); break;
        }
    }

    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    render() {

        const { visibleModal, itemDelValue, tabs, submittedForm } = this.state;

        return (
            <div style={{ width: '100%' }}>
                <Sidemenu />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>Orientações</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Modal isOpen={visibleModal} toggle={this.toggleModal}>
                                            <ModalHeader toggle={this.toggleModal}>Confirmação</ModalHeader>
                                            <ModalBody>
                                                Tem certeza que deseja excluir este registro?
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="danger" onClick={() => this.removeItem(itemDelValue)}>Sim, com certeza</Button>
                                                <Button color="primary" onClick={this.toggleModal}>Não</Button>
                                            </ModalFooter>
                                        </Modal>
                                        <Tabs
                                            fnCallback={this.selectTab}
                                            selected={1}
                                            campos={tabs}
                                        />
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_crianca_title">Descrição:</label>
                                                    <input
                                                        type="text"
                                                        name="ds_medicamentoorientacao"
                                                        className="form-control"
                                                        value={this.state.ds_medicamentoorientacao}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {submittedForm && this.state.ds_medicamentoorientacao === '' && this.requiredField()}
                                                </div>
                                            </div>

                                            <div className="form-group float-left">
                                                <div onClick={this.clearSelectItem} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                    Novo
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                        <div className="table-responsive m-t-20">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: 10 }}></th>
                                                        <th style={{ width: 10 }}></th>
                                                        <th>Descrição</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.renderItems()}
                                                </tbody>
                                            </table>
                                            <Pagination
                                                currentPage={this.state.currentPage}
                                                totalPages={this.state.totalReg}
                                                totalSize={this.state.totalReg}
                                                sizePerPage={this.state.regperpage}
                                                changeCurrentPage={this.changeCurrentPage}
                                                theme="bootstrap"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}