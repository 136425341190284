import React, { Component } from 'react'

import api from '../../services';
import Pagination from "react-pagination-js";

import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Tabs from '../../components/Tabs';
import moment from "moment";
import 'moment/locale/pt-br';

export default class AcessoWebCliente extends Component{
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',
            data: [],

            cd_cliente: '', 
            cd_clientecelular: '', 
            ds_clientecelular_modelo: '',
            ds_clientecelular_macaddress: '',
            dt_clientecelular_inclusao: '',
            tabs: ['Cliente', 'Celulares', 'Acesso Web'],
            displaySubtopico: '',
            itemDelValue: '', 
            itemDelDisplay: '', 
            filteredData: null,
            selectedItem: null,
            regperpage: 10,
            totalReg: 0,
            totalPages: 1,
            regini: 0,
            regfim: 0,
            currentPage: 1,
            reloadForm: false,
        };

    }


    componentWillMount(){
        const par = this.props.match.params;

        if (par.cd_cliente !== undefined) {
            this.setState({
                cd_cliente: par.cd_cliente
            }, () => {
                this.fetchData();
            });
        }
    }
    
    changeCurrentPage = numPage => {
        this.setState({ currentPage: numPage });
    };

    /* LISTAGEM DE DADOS */
    fetchData = async() => {

        try { 

            this.setState({ isLoading: true });
            
            const response = await api.get(`/acessowebcliente/${this.state.cd_cliente}`);

            if( response.data !== undefined ){

                let dados = response.data.data.acessowebcliente;

                this.setState({ 
                    isLoading: false,
                    data: dados,
                    totalReg: dados.length
                });
      
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.."});
            }
      
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }

    }

    renderItem = (item) => {
        return (
            <tr key={item.cd_clientecelular}>
                <td className="tdColumn">{ item.ds_acessowebcliente_browsername }</td>
                <td className="tdColumn">{ item.ds_acessowebcliente_browserfullversion }</td>
                <td className="tdColumn">{ item.ds_acessowebcliente_osname }</td>
                <td className="tdColumn">{ item.ds_acessowebcliente_osversion }</td>
                <td className="tdColumn">{ moment(new Date(item.dt_acessowebcliente)).format("DD/MM/YYYY [às] H:mm") }</td>
            </tr>
        )
    }

    renderItems = () => {
        const { data, currentPage, regperpage } = this.state;
        
        let regfim = currentPage * regperpage;
        let regini = regfim - regperpage;
        if( data.length > 0 ){
            return data.map((item, index) => {
                if (index >= regini && index < regfim)
                    return this.renderItem(item);
            })  
        } else {
            return (
                <tr>
                    <td colSpan="5">Nenhum registro encontrado</td>
                </tr>
            )
        }
    }

    selectTab = (index) => {
        switch (index){
            case 0: this.props.history.push(`/cliente/form/${this.state.cd_cliente}`); break;
            case 1: this.props.history.push(`/clientecelular/${this.state.cd_cliente}`); break;
            case 2: this.props.history.push(`/acessowebcliente/${this.state.cd_cliente}`); break;
        }
    }
    
    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    render(){

        const { itemDelValue, tabs} = this.state;
        
        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>Acesso Web</h2>
                                        </div>
                                        
                                        <div className="float-right">
                                            <Link to={`/cliente/form/`} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Novo Cliente
                                            </Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Tabs 
                                            fnCallback={this.selectTab}
                                            selected={2}
                                            campos={tabs}
                                        />
                                        <div className="table-responsive m-t-20">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Navegador</th>
                                                    <th>Versão</th>
                                                    <th>Sistema Operacional</th>
                                                    <th>Versão</th>
                                                    <th>Data de acesso</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {this.renderItems()}
                                                </tbody>
                                            </table>
                                            <Pagination
                                                currentPage={this.state.currentPage}
                                                totalPages={this.state.totalReg}
                                                totalSize={this.state.totalReg}
                                                sizePerPage={this.state.regperpage}
                                                changeCurrentPage={this.changeCurrentPage}
                                                theme="bootstrap"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}