import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export default class Header extends React.Component {

    state = {
        cd_usuario: ''
    }

    componentWillMount(){
        const cd_usuario = localStorage.getItem('@guiabasico/cd_usuario');
        this.setState({
            cd_usuario
        })
    }

    logout = () => {
        localStorage.removeItem('@guiabasico/cd_usuario');

        window.location='./'
    }

    render() {
      return(
        <header className="admin-header">
  
          <a href="#" className="sidebar-toggle" data-toggleclass="sidebar-open" data-target="body"> </a>
  
          <nav className=" ml-auto">
              <ul className="nav align-items-center">
  
                  <li className="nav-item">
                      <div className="dropdown">
                          
  
                          <div className="dropdown-menu notification-container dropdown-menu-right">
                              <div className="d-flex p-all-15 bg-white justify-content-between border-bottom ">
                                  <a href="#!" className="mdi mdi-18px mdi-settings text-muted"></a>
                                  <span className="h5 m-0">Notificações</span>
                                  <a href="#!" className="mdi mdi-18px mdi-notification-clear-all text-muted"></a>
                              </div>
                              <div className="notification-events bg-gray-300">
                                  <div className="text-overline m-b-5">hoje</div>
                                  <a href="#" className="d-block m-b-10">
                                      <div className="card">
                                          <div className="card-body"> <i className="mdi mdi-circle text-success"></i> 1 Nova solicitação de orçamento.</div>
                                      </div>
                                  </a>
                                  <a href="#" className="d-block m-b-10">
                                      <div className="card">
                                      <div className="card-body"> <i className="mdi mdi-circle text-success"></i> 1 Nova solicitação de orçamento.</div>
                                      </div>
                                  </a>
                                  <a href="#" className="d-block m-b-10">
                                      <div className="card">
                                      <div className="card-body"> <i className="mdi mdi-circle text-success"></i> 1 Nova solicitação de orçamento.</div>
                                      </div>
                                  </a>
                                
  
  
                              </div>
  
                          </div>
                      </div>
                  </li>
                  <li className="nav-item dropdown ">
                      <a className="nav-link dropdown-toggle" href="#"   role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                          <div className="avatar avatar-sm avatar">
                              <img src="/assets/img/logo_reduzida.png" className="rounded-circle" alt="Thiago Lino" />
  
                          </div>
                      </a>
                      <div className="dropdown-menu  dropdown-menu-right"   >
                          <Link className="dropdown-item" to={`/usuario/form/${this.state.cd_usuario}`}>Meus Dados</Link>
                          <Link className="dropdown-item" to={'/trocarsenha'}>Alterar Senha</Link>
                          <div className="dropdown-divider"></div>
                          <div className="dropdown-item" onClick={this.logout}>Sair</div>
                      </div>
                  </li>
  
              </ul>
  
          </nav>
        </header>
      )
    }
  }