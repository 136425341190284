import React from 'react';

export default class Inicio extends React.Component {
    render() {
      return(
        <section className="admin-content">
          <div className="container p-t-20">
              <div className="row">
                  <div className="col-12 m-b-30">
                      <h3>Dashboard</h3>
                  </div>
                  
                  <div className="col-12 m-b-20">
                      <h5> <i className="fe fe-zap"></i> Estatísticas</h5>
                  </div>
                  <div className="col-lg-3 col-md-6">
                      <div className="card m-b-30">
                          <div className="card-body">
                              <div className="pb-2">
                                  <div className="avatar avatar-lg">
                                      <div className="avatar-title bg-soft-info rounded-circle">
                                      <i className="mdi mdi-account-group"></i>
  
  
  
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <p className="text-muted text-overline m-0">Total de Clientes</p>
                                    <h1 className="fw-400">{this.props.qtdClientes}</h1>
                              </div>
                          </div>
                      </div>
                  </div>
  
                  <div className="col-lg-3 col-md-6">
                      <div className="card m-b-30">
                          <div className="card-body">
                              <div className="pb-2">
                                  <div className="avatar avatar-lg">
                                      <div className="avatar-title bg-soft-info rounded-circle">
                                      <i className="mdi mdi-account-group"></i>
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <p className="text-muted text-overline m-0">Total de Prospects</p>
                                  <h1 className="fw-400">{this.props.qtdProspects}</h1>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                      <div className="card m-b-30">
                          <div className="card-body">
                              <div className="pb-2">
                                  <div className="avatar avatar-lg">
                                      <div className="avatar-title bg-soft-info rounded-circle">
                                          <i className="mdi mdi-finance"></i>
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <p className="text-muted text-overline m-0">Total de Vendas</p>
                                  <h1 className="fw-400">{this.props.qtdVendas}</h1>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                      <div className="card m-b-30">
                          <div className="card-body">
                              <div className="pb-2">
                                  <div className="avatar avatar-lg">
                                      <div className="avatar-title bg-soft-info rounded-circle">
                                      <i className="mdi mdi-file-document-outline"></i>
                                        </div>
                                  </div>
                              </div>
                              <div>
                                  <p className="text-muted text-overline m-0">Temas cadastrados</p>
                                  <h1 className="fw-400">{this.props.qtdTemas}</h1>
                              </div>
                          </div>
                      </div>
                  </div>
  
  
  
  
                  </div>
              </div>
          
        </section>
      )
    }
  }