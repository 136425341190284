import React, { Component } from 'react';
import api from '../../services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../includes/Header';
import Tabs from '../../components/Tabs';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import FileBase64 from 'react-file-base64';
import Pagination from "react-pagination-js";

toast.configure();

export default class QuestoesRespostas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tooltip2: '',
            tabs: ["Edições", "Questões"],
            modalFull: false,
            popoverOpenImg: false,
            // QUESTÕES
            cd_anoprova: '',
            cd_prova: '',
            cd_questao: '',
            ds_questao: '',
            ds_questao_obs: '',
            ds_questao_imagem: '',
            ds_prova: '',
            anosProva: [],
            data: [],
            visibleModal: false,
            itemDelValue: '',
            viewQuestaoImage: false,
            selectedImage: null,

            filteredDataQuestao: null,
            regperpageQuestao: 10,
            currentPageQuestao: 1,
            // RESPOSTAS
            dataResposta: [],
            cd_questaoresposta: '',
            ds_questaoresposta: '',
            id_questaoresposta_correta: false,
            visibleModalResposta: false,
            itemDelValueResposta: '',
            is_edit: false,

            filteredDataResposta: null,
            regperpageResposta: 10,
            currentPageResposta: 1,
        };
    }

    componentDidMount() {
        const prova = JSON.parse(localStorage.getItem('ds_prova'));
        this.setState({ ds_prova: prova });
        this.consultaAnosProva();
    }

    handleInputChange = (event) => {
        const target = event.target;
        
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    voltar = () => {
        this.props.history.push(`/prova/anoprova/${this.state.cd_prova}`);
    }

    changeNovaProva = () => {
        this.props.history.push('/prova/form');
    }

    // QUESTOES
    handleSubmitQuestao = async (event) => {
        event.preventDefault();

        try {
            const regex = /^data:image\/\w+;base64,/;
            let ds_questao_imagem = this.state.ds_questao_imagem;

            if (this.state.ds_questao_imagem && !regex.test(this.state.ds_questao_imagem)) {
                ds_questao_imagem = `data:image/webp;base64,${this.state.ds_questao_imagem}`;
            }
            const response = this.state.cd_questao != ''
                            ? await api.put(`/questao/${this.state.cd_questao}`, {...this.state, ds_questao_imagem})
                            : await api.post(`/questao/`, this.state);
            

            if (response.data.errors === "") {
                toast.success(
                    `Questão ${this.state.cd_questao != '' ? 'editada' : 'inserida'} com sucesso!`,
                    { autoClose: 3000, toastId: 1 }
                );

                this.consultaAPI();
                this.setState({ ds_questao: '', cd_questao: '', ds_questao_imagem: '', ds_questao_obs: '' });
            } else {
                toast.error(response.data.errors, { autoClose: 3000, toastId: 1 });
            }

        } catch (resp) {
            console.log(resp)
            this.setState({ tooltip2: "Houve um erro" });
        }
    }

    consultaAnosProva = async () => {
        try {
            const params = this.props.match.params;
            const cd_prova = params.id ? params.id : '';
    
            this.setState({ isLoading: true, cd_prova });
            
            const response = await api.get(`/anoprova/?cd_prova=${cd_prova}`);
            
            this.setState({
                anosProva: response.data.data,
                isLoading: false,
            });
        } catch (error) {
            console.error(error);
            this.setState({ isLoading: false });
        }
    };

    handleAnoChange = (event) => {
        const cd_anoprova = event.target.value;

        this.setState({ cd_anoprova, data: [] }, () => {
            if (cd_anoprova) {
                this.consultaAPI();
            }
        });
    };

    consultaAPI = async () => {
        try {
            this.setState({ isLoading: true });
            const response = await api.get(`/questao/?anoprova=${this.state.cd_anoprova}`);
            const dados = response.data.data;

            this.setState({
                data: dados,
                isLoading: false,
            });
        } catch (error) {
            console.error(error);
            this.setState({ isLoading: false });
            toast.error('Erro ao buscar questões!', { autoClose: 3000 });
        }
    };

    confirmRemoveItem = ({ cd_questao }) => {
        this.setState({ 
            visibleModal: !this.state.visibleModal,
            itemDelValue: cd_questao, 
        });
    }

    removeItem = async (id) => {
        try { 
            const response = await api.delete(`/questao/${id}`);

            if( response.data.errors === "" ){
                this.setState({ tooltip2: "Questão excluída com sucesso!"});  
                this.consultaAPI();
            } else {
                this.setState({ tooltip2: "Dificuldades Técnicas.."});
            }

            this.setState({ visibleModal: false, ds_questao: '' });
      
        } catch (response) {
            console.log(response)
            this.setState({ tooltip2: "Houve um erro"});
        }
    }

    toggleModal = () => {
        this.setState({ visibleModal: !this.state.visibleModal })
    }

    toggleModalFull = (item = '') => {
        this.setState({ modalFull: !this.state.modalFull, cd_questao: item.cd_questao });
        this.consultaRespostas(item.cd_questao);
    }

    selectItem = async (item) => {
        const response = await api.get(`/questao/${item.cd_questao}`);
        const dados = response.data.data;

        this.setState({
            cd_questao: dados.cd_questao,
            ds_questao: dados.ds_questao,
            ds_questao_obs: dados.ds_questao_obs,
            ds_questao_imagem: dados.ds_questao_imagem,
        });
    }

    selectTab = (index) => {
        switch (index) {
            case 0: this.props.history.push(`/prova/anoprova/${this.state.cd_prova}`); break;
            case 1: this.props.history.push(`/prova/questoes/${this.state.cd_prova}`); break;
        }
    }

    sendImage = (files) => {
        if (files.base64 !== '') {
            this.setState({
                ds_questao_imagem: files.base64
            });
        }
    }

    clearImg = () => {
        this.setState({
            ds_questao_imagem: ''
        }, () => console.log(this.state));
    }

    toggleImg = () => {
        this.setState(prevState => ({ popoverOpenImg: !prevState.popoverOpenImg }));
    };

    returnImg = (imagem) => {
        if (imagem === undefined || imagem === null)
            imagem = '';
    
        const regex = /^data:image\/\w+;base64,/;
        if (imagem && !regex.test(imagem)) {
            imagem = `data:image/webp;base64,${imagem}`;
        }

        const { popoverOpenImg } = this.state;
        return (imagem !== '') && (
            <div style={{ width: '100%' }}>
                <Button className="btn btnSalvarConteudo btn-primary" id="PopoverClick" type="button" style={{ color: 'white', float: 'left' }}>
                    <i className="mdi mdi-eye-outline"></i>
                </Button>
                <Button className="btn btnSalvarConteudo btn-danger" onClick={this.clearImg}  type="button" style={{ color: 'white', float: 'left', marginLeft: 10 }}>
                    <i className="mdi mdi-trash-can"></i>
                </Button>
                <Popover
                placement="right"
                isOpen={popoverOpenImg}
                target="PopoverClick"
                toggle={this.toggleImg}
                dir="right"
                >
                    <PopoverHeader>
                        Visualizar Imagem
                        <div style={{ float: 'right', cursor: 'pointer', marginTop: -5 }} onClick={this.toggleImg}>
                            <i className="mdi mdi-24px mdi-close"></i>
                        </div>
                    </PopoverHeader>
                    <PopoverBody>
                        <img src={`${imagem}`} style={{ width: '100%', height: '100%' }} alt="Imagem enviada" />
                    </PopoverBody>
                </Popover>
            </div>
        )
    }

    toggleViewImageQuestao = (imagem) => {
        this.setState({
            viewQuestaoImage: !this.state.viewQuestaoImage,
            selectedImage: imagem || null
        });
    };

    clearStateQuestao = () => {
        this.setState({
            cd_questao: '',
            ds_questao: '',
            ds_questao_obs: '',
            ds_questao_imagem: '',        });
    }

    changeCurrentPageQuestao = (numPage) => {
        this.setState({ currentPageQuestao: numPage });
    };

    renderItemQuestao = (item) => {
        return (
            <tr key={item.cd_questao}>
                <td className="tdClickable" onClick={() => this.confirmRemoveItem(item)}>
                    <div className="deletar">
                        <i className="mdi mdi-24px mdi-delete-circle" style={{ color: '#f2545b' }}></i>
                    </div>
                </td>
                <td className="tdClickable" onClick={() => this.selectItem(item)}>
                    <i className="mdi mdi-24px mdi-pencil-circle"></i>
                </td>
                <td className="tdClickable" onClick={() => this.toggleModalFull(item)}>
                    <i className="mdi mdi-24px mdi-view-list"></i>
                </td>
                <td className="tdColumn">{item.ds_questao}</td>
                <td className="tdColumn text-center">{item.ds_questao_obs ?? '-'}</td>
                <td className="tdColumn text-center">
                    {item.ds_questao_imagem !== null ? (
                        <Button className="btn btn-primary" onClick={() => this.toggleViewImageQuestao(item.ds_questao_imagem)} type="button">
                            <Modal isOpen={this.state.viewQuestaoImage} toggle={() => this.toggleViewImageQuestao(null)} className="custom-fullscreen-modal-image">
                                <ModalBody>
                                    {this.state.selectedImage ? (
                                        <img src={`data:image/webp;base64,${this.state.selectedImage}`} style={{ width: '100%', height: 'auto' }} alt="Imagem item" />
                                    ) : (
                                        <p>Nenhuma imagem disponível</p>
                                    )}
                                </ModalBody>
                            </Modal>
                            <i className="mdi mdi-eye-outline"></i>
                        </Button>
                    ) : <div className='text-center'>-</div>}
                </td>
            </tr>
        );
    }
    
    renderItemsQuestao = () => {
        const { data, filteredDataQuestao, currentPageQuestao, regperpageQuestao } = this.state;
    
        let regfim = currentPageQuestao * regperpageQuestao;
        let regini = regfim - regperpageQuestao;
    
        if (data.length > 0) {
            const dados = (filteredDataQuestao && filteredDataQuestao.length > 0) ? filteredDataQuestao : data;
            return dados.slice(regini, regfim).map((item) => this.renderItemQuestao(item));
        } else {
            return (
                <tr className='text-center'>
                    <td colSpan="5">Nenhum registro encontrado</td>
                </tr>
            );
        }
    }

    // RESPOSTAS
    handleSubmitResposta = async (event) => {
        event.preventDefault();

        this.setState({
            isLoading: true,
        });

        try {
            const possuiCorreta = this.state.dataResposta.some((item) => item.id_questaoresposta_correta === 1);
            
            if (this.state.id_questaoresposta_correta === 'true' && possuiCorreta) {
                toast.error('Já existe uma resposta correta!', { autoClose: 3000, toastId: 1 });
                this.setState({ isLoading: false });
                return;
            } 
            const response = this.state.cd_questaoresposta != ''
                            ? await api.put(`/questoesrespostas/${this.state.cd_questaoresposta}`, this.state)
                            : await api.post(`/questoesrespostas/`, this.state);

            if (response.data.errors === "") {
                toast.success(
                    `Resposta ${this.state.cd_questao != '' ? 'editada' : 'inserida'} com sucesso!`,
                    { autoClose: 3000, toastId: 1 }
                );

                this.setState({ isLoading: false, ds_questaoresposta: '', cd_questaoresposta: '', id_questaoresposta_correta: false });
                this.consultaRespostas(this.state.cd_questao);
            } else {
                toast.error(response.data.errors, { autoClose: 3000, toastId: 1 });
            }

        } catch (resp) {
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    toggleModalResposta = () => {
        this.setState({ visibleModalResposta: !this.state.visibleModalResposta })
    }

    selectItemResposta = async (item) => {
        const response = await api.get(`/questoesrespostas/${item.cd_questaoresposta}`);
        const dados = response.data.data;

        this.setState({
            cd_questaoresposta: dados.cd_questaoresposta,
            cd_questao: dados.cd_questao,
            ds_questaoresposta: dados.ds_questaoresposta,
            id_questaoresposta_correta: dados.id_questaoresposta_correta === 1 ?? true,
            is_edit: true,
        });
    }

    confirmRemoveItemResposta = ({ cd_questaoresposta }) => {
        this.setState({ 
            visibleModalResposta: !this.state.visibleModalResposta,
            itemDelValueResposta: cd_questaoresposta, 
        });
    }

    consultaRespostas = async (cd_questao) => {
        const response = await api.get(`/questoesrespostas/?cd_questao=${cd_questao}`);

        try {
            this.setState({
                dataResposta: response.data.data,
            });
        } catch (error) {
            console.error(error);
            toast.error('Erro ao buscar respostas!', { autoClose: 3000 });
        }
    }

    removeItemResposta = async (id) => {
        try { 
            const response = await api.delete(`/questoesrespostas/${id}`);

            if (response.data.errors === "") {
                toast.success('Resposta deletada com sucesso!', { autoClose: 3000 });
            } else {
                this.setState({ tooltip2: "Dificuldades Técnicas.."});
            }

            this.setState({ visibleModalResposta: false, ds_questaoresposta: '' });
            this.consultaRespostas(this.state.cd_questao);
        } catch (response) {
            console.log(response)
            this.setState({ tooltip2: "Houve um erro"});
        }
    }

    clearStateResposta = () => {
        this.setState({
            cd_questaoresposta: '',
            ds_questaoresposta: '',
            id_questaoresposta_correta: false,
        });
    }

    changeCurrentPageReposta= (numPage) => {
        this.setState({ currentPageResposta: numPage });
    };

    renderItemResposta = (item) => {
        return (
            <tr key={item.cd_questaoresposta}>
                <td className="tdClickable" onClick={() => this.confirmRemoveItemResposta(item)}>
                    <div className="deletar">
                        <i className="mdi mdi-24px mdi-delete-circle" style={{ color: '#f2545b' }}></i>
                    </div>
                </td>
                <td className="tdClickable" onClick={() => this.selectItemResposta(item)}>
                    <i className="mdi mdi-24px mdi-pencil-circle"></i>
                </td>
                <td className="tdColumn">{item.ds_questaoresposta}</td>
                <td className="tdColumn" style={{ textAlign: 'center' }}>
                    {item.id_questaoresposta_correta ? (
                        <i className="mdi mdi-check"></i>
                    ) : (
                        <i className="mdi mdi-close"></i>
                    )}
                </td>
            </tr>
        );
    };
    
    renderItemsResposta = () => {
        const { dataResposta, filteredDataResposta, currentPageResposta, regperpageResposta } = this.state;
        
        let regfim = currentPageResposta * regperpageResposta;
        let regini = regfim - regperpageResposta;
        
        if (dataResposta.length > 0) {
            const dados = (filteredDataResposta && filteredDataResposta.length > 0) ? filteredDataResposta : dataResposta;
            return dados.map((item, index) => {
                if (index >= regini && index < regfim)
                    return this.renderItemResposta(item);
            });
        } else {
            return (
                <tr className="text-center">
                    <td colSpan="4">Nenhuma resposta encontrada</td>
                </tr>
            );
        }
    };

    render() {
        const { isLoading,
                anosProva,
                data,
                visibleModal,
                itemDelValue,
                modalFull,
                dataResposta,
                visibleModalResposta,
                itemDelValueResposta,
                is_edit,
                tabs,
                ds_questao_imagem
            } = this.state;
        const imagemQuestao = (ds_questao_imagem === undefined || ds_questao_imagem === null) ? '' : ds_questao_imagem;

        return (
            <div style={{ width: '100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container m-b-30">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 p-t-20 p-b-70">
                                        <div className="media">
                                            <div className="avatar avatar mr-3">
                                                <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                            </div>
                                            <div className="media-body">
                                                <h2>{this.state.cd_prova === '' ? 'Nova Prova' : `Prova ${this.state.ds_prova && '- ' + this.state.ds_prova}`}</h2>
                                            </div>

                                            <div className="float-right">
                                                <Button onClick={() => this.changeNovaProva()} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                    <i className="mdi mdi-plus"></i> Nova Prova
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container pull-up">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Tabs
                                            fnCallback={this.selectTab}
                                            selected={1}
                                            campos={tabs}
                                        />

                                        <div className="form-group m-t-20">
                                            <label htmlFor="cd_anoprova">Selecione o Ano:</label>
                                            <select
                                                className="form-control col-md-3"
                                                name="cd_anoprova"
                                                value={this.state.cd_anoprova}
                                                onChange={this.handleAnoChange}
                                            >
                                                <option value="">Selecione...</option>
                                                {anosProva.map((ano) => (
                                                    <option key={ano.cd_anoprova} value={ano.cd_anoprova}>
                                                        {ano.ds_anoprova}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        { this.state.cd_anoprova !== '' && (
                                        <form onSubmit={this.handleSubmitQuestao} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_questao">Descrição:</label>
                                                    <textarea 
                                                        name="ds_questao" 
                                                        className="form-control" 
                                                        style={{ height: 100 }}
                                                        value={this.state.ds_questao}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_questao_obs">Observação:</label>
                                                    <textarea 
                                                        name="ds_questao_obs" 
                                                        className="form-control" 
                                                        style={{ height: 80 }}
                                                        value={this.state.ds_questao_obs}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                                 <div className="form-group col-md-6">
                                                    <label>Imagem:</label>
                                                    
                                                    <div className="input-group mb-6">
                                                        { imagemQuestao !== '' 
                                                        ? this.returnImg(imagemQuestao) 
                                                        : (
                                                        <div style={{ marginTop: 5 }}>
                                                            <FileBase64
                                                                onDone={this.sendImage.bind(this)}
                                                            />
                                                        </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                    Voltar
                                                </div>
                                                <div onClick={this.clearStateQuestao} className="btn-primary btn m-l-10 " style={{ cursor: 'pointer' }}>
                                                    Novo
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>)}

                                        {data.length > 0 ? (
                                            <div className="table-responsive m-t-20">
                                                <Modal isOpen={visibleModal} toggle={this.toggleModal}>
                                                    <ModalHeader toggle={this.toggleModal}>Confirmação</ModalHeader>
                                                    <ModalBody>
                                                    Tem certeza que deseja excluir esta questão?
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="danger" onClick={() => this.removeItem(itemDelValue)}>Sim, com certeza</Button>
                                                        <Button color="primary" onClick={this.toggleModal}>Não</Button>
                                                    </ModalFooter>
                                                </Modal>

                                                <Modal isOpen={visibleModalResposta} toggle={this.toggleModalResposta}>
                                                    <ModalHeader toggle={this.toggleModalResposta}>Confirmação</ModalHeader>
                                                    <ModalBody>
                                                    Tem certeza que deseja excluir esta resposta?
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="danger" onClick={() => this.removeItemResposta(itemDelValueResposta)}>Sim, com certeza</Button>
                                                        <Button color="primary" onClick={this.toggleModalResposta}>Não</Button>
                                                    </ModalFooter>
                                                </Modal>

                                                <Modal isOpen={modalFull} toggle={this.toggleModalFull} className="custom-fullscreen-modal">
                                                    <ModalHeader toggle={this.toggleModalFull}>Respostas</ModalHeader>
                                                    <ModalBody>
                                                        <form onSubmit={this.handleSubmitResposta} className="formGeral m-t-30">
                                                            <div className="form-row" >
                                                                <div className="form-group col-md-9">
                                                                    <label htmlFor="ds_questaoresposta">Descrição:</label>
                                                                    <textarea 
                                                                        name="ds_questaoresposta" 
                                                                        className="form-control" 
                                                                        style={{ height: 60 }}
                                                                        value={this.state.ds_questaoresposta}
                                                                        onChange={this.handleInputChange} 
                                                                    />
                                                                </div>
                                                                
                                                                <div className="form-group col-md-3">
                                                                    <label htmlFor="id_questaoresposta_correta">Correta?:</label>
                                                                    <select
                                                                        className="form-control"
                                                                        disabled={!is_edit && dataResposta.some((item) => item.id_questaoresposta_correta === 1)}
                                                                        name="id_questaoresposta_correta"
                                                                        value={this.state.id_questaoresposta_correta}
                                                                        onChange={this.handleInputChange}
                                                                    >
                                                                        <option value=''>Selecione...</option>
                                                                        <option value={true}>Sim</option>
                                                                        <option value={false}>Não</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group float-left">
                                                                <div onClick={this.toggleModalFull} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                                    Voltar
                                                                </div>
                                                                <div onClick={this.clearStateResposta} className="btn-primary btn m-l-10 " style={{ cursor: 'pointer' }}>
                                                                    Novo
                                                                </div>
                                                            </div>
                                                            <div className="form-group float-right">
                                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                                            </div>
                                                        </form>
                                                        {dataResposta.length > 0 ? (
                                                        <>
                                                            <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: 10 }}></th>
                                                                    <th style={{ width: 10 }}></th>
                                                                    <th>Descrição</th>
                                                                    <th style={{ textAlign: 'center' }}>Resposta correta</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.renderItemsResposta()}
                                                            </tbody>
                                                        </table>
                                                        
                                                        <Pagination
                                                            currentPage={this.state.currentPageResposta}
                                                            totalPages={Math.ceil(this.state.dataResposta.length / this.state.regperpageResposta)}
                                                            totalSize={this.state.dataResposta.length}
                                                            sizePerPage={this.state.regperpageResposta}
                                                            changeCurrentPage={this.changeCurrentPageReposta}
                                                            theme="bootstrap"
                                                        />
                                                        </>
                                                        ) : (
                                                            <div className="m-t-40 text-center">
                                                                <p>Nenhuma resposta encontrada</p>
                                                            </div>
                                                        )} 
                                                    </ModalBody>
                                                </Modal>
                                                
                                                <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: 10 }}></th>
                                                        <th style={{ width: 10 }}></th>
                                                        <th style={{ width: 10 }}></th>
                                                        <th>Descrição</th>
                                                        <th className='text-center'>Observação</th>
                                                        <th className='text-center'>Imagem</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.renderItemsQuestao()}
                                                </tbody>
                                            </table>

                                            <Pagination
                                                currentPage={this.state.currentPageQuestao}
                                                totalPages={Math.ceil((this.state.filteredDataQuestao?.length || this.state.data.length) / this.state.regperpageQuestao)}
                                                totalSize={this.state.filteredDataQuestao?.length || this.state.data.length}
                                                sizePerPage={this.state.regperpageQuestao}
                                                changeCurrentPage={this.changeCurrentPageQuestao}
                                                theme="bootstrap"
                                            />
                                               
                                            </div>
                                        ) : this.state.cd_anoprova && (
                                            <div className="m-t-20 text-center">
                                                <p>Nenhuma questão encontrada</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}