import React from 'react';
import { BrowserRouter,Switch, Route } from 'react-router-dom';
import Login from './pages/Login';
import Main from './pages/Main';
import Topico from './pages/Topico';
import Subtopico from './pages/Subtopico/index';
import Itemsubtopico from './pages/Itemsubtopico/index';
import Crianca from './pages/Crianca/index';
import Tema from './pages/Tema';
import TemaForm from './pages/Tema/form';
import Usuario from './pages/Usuario';
import UsuarioForm from './pages/Usuario/form';
import Prospect from './pages/Prospect';
import Cliente from './pages/Cliente';
import ClienteCelular from './pages/ClienteCelular';
import ClienteForm from './pages/Cliente/form';
import Venda from './pages/Venda';
import VendaForm from './pages/Venda/form';
import Grupousuario from './pages/Grupousuario';
import GrupousuarioForm from './pages/Grupousuario/form';
import RelacionamentoForm from './pages/Grupousuario/relacionamento';
import Funcaosistema from './pages/Funcaosistema';
import FuncaosistemaForm from './pages/Funcaosistema/form';
import Formapgto from './pages/Formapgto';
import FormapgtoForm from './pages/Formapgto/form';
import Notificacao from './pages/Notificacao';
import NotificacaoForm from './pages/Notificacao/form';
import Termosdeuso from './pages/Termosdeuso';
import Sobre from './pages/Sobre';
import Referencia from './pages/Referencia';
import Trocarsenha from './pages/Trocarsenha';
import Faleconosco from './pages/Faleconosco';
import Instagram from './pages/Instagram';
import AcessoWebCliente from './pages/AcessoWebCliente';
import CategoriaMedicamento from './pages/Categoriamedicamento';
import CategoriaMedicamentoForm from './pages/Categoriamedicamento/form';
import Medicamento from './pages/Medicamento/';
import MedicamentoForm from './pages/Medicamento/form';
import MedicamentOrientacao from './pages/Medicamentoorientacao';
import MedicamentoIndicacaoClinica from './pages/Medicamentoindicacaoclinica';
import MedicamentoVariacao from './pages/Medicamentovariacao';
import Prova from './pages/Prova/';
import ProvaForm from './pages/Prova/form';
import AnoProva from './pages/Prova/anoprova';
import QuestoesRespostas from './pages/Prova/questoes';

export default function Routes() {
  return (
    <BrowserRouter>
        <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/inicio" component={Main} />
            <Route path="/tema" exact component={Tema} />
            <Route path="/tema/form/:id?" component={TemaForm} />
            <Route path="/subtopico/:id" exact component={Subtopico} />
            <Route path="/itemsubtopico/:id" exact component={Itemsubtopico} />
            <Route path="/crianca/:id" exact component={Crianca} />
            <Route path="/topico/:id" exact component={Topico} />
            <Route path="/usuario" exact component={Usuario} />
            <Route path="/usuario/form/:id?" component={UsuarioForm} />
            <Route path="/cliente" exact component={Cliente} />
            <Route path="/clientecelular/:id?" component={ClienteCelular} />
            <Route path="/cliente/form/:id?" component={ClienteForm} />
            <Route path="/venda" exact component={Venda} />
            <Route path="/venda/form/:id?" component={VendaForm} />
            <Route path="/grupousuario" exact component={Grupousuario} />
            <Route path="/grupousuario/form/:id?" component={GrupousuarioForm} />
            <Route path="/relacionamento/form/:id?" component={RelacionamentoForm} />
            <Route path="/funcaosistema" exact component={Funcaosistema} />
            <Route path="/funcaosistema/form/:id?" component={FuncaosistemaForm} />
            <Route path="/notificacao" exact component={Notificacao} />
            <Route path="/notificacao/form/:id?" component={NotificacaoForm} />
            <Route path="/formapgto" exact component={Formapgto} />
            <Route path="/formapgto/form/:id?" component={FormapgtoForm} />
            <Route path="/termosdeuso" exact component={Termosdeuso} />
            <Route path="/sobre" exact component={Sobre} />
            <Route path="/referencia" exact component={Referencia} />
            <Route path="/trocarsenha" exact component={Trocarsenha} />
            <Route path="/faleconosco" exact component={Faleconosco} />
            <Route path="/instagram" exact component={Instagram} />
            <Route path="/prospect" exact component={Prospect} />
            <Route path="/acessowebcliente/:cd_cliente" exact component={AcessoWebCliente} />
            <Route path="/categoriamedicamento/" exact component={CategoriaMedicamento} />
            <Route path="/categoriamedicamento/form/:id?" component={CategoriaMedicamentoForm} />
            <Route path="/medicamento/" exact component={Medicamento} />
            <Route path="/medicamento/form/:id?" exact component={MedicamentoForm} />
            <Route path="/medicamentoorientacao/:id" exact component={MedicamentOrientacao} />
            <Route path="/medicamentoindicacaoclinica/:id" exact component={MedicamentoIndicacaoClinica} />
            <Route path="/medicamentovariacao/:id" exact component={MedicamentoVariacao} />
            <Route path="/prova/" exact component={Prova} />
            <Route path="/prova/form/:id?" exact component={ProvaForm} />
            <Route path="/prova/anoprova/:id" exact component={AnoProva} />
            <Route path="/prova/questoes/:id" exact component={QuestoesRespostas} />
        </Switch>
    </BrowserRouter>
  );
}
