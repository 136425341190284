import React, { Component } from 'react'

import api from '../../services';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';

import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';

toast.configure();

export default class UsuarioForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: false,
            popoverOpenImg: false,
            submittedForm: false,
            formError: false,
            tooltip2: '',

            cd_usuario: '', 
            ds_usuario_senha: '', 
            ds_usuario_senha2: '', 
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        
        const target = event.target;
        const name = target.name;
        var value2 = target.type === 'checkbox' ? target.checked : target.value;
        
        this.setState({
            [name]: value2
        });
    }

    componentDidMount(){

        const id = localStorage.getItem('@guiabasico/cd_usuario');
        
        if (id !== undefined) {
            this.setState({
                cd_usuario: id
            })
        } 
    }

    validation = () => {
        const { 
            ds_usuario_senha, 
            ds_usuario_senha2, 
        } = this.state;

        var continuar = true;

        if (ds_usuario_senha === '' && ds_usuario_senha2 === '') {
            continuar = false;
        }

        if (ds_usuario_senha !== ds_usuario_senha2) {
            toast.error('Senhas diferentes', { autoClose: 3000, toastId: 1 });
            continuar = false;
        }

        return continuar;
    }

    /**
     * Função padrão para envio de Formulário
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ 
            submittedForm: true, 
        });
        
        if (!this.validation()) {
            return false;
        }
        /* Função padrão para popular objetos na tela */
        /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
        /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */
        
        try { 
            console.log(this.state)
            var response = await api.put(`/updateSenhaUs/${this.state.cd_usuario}`,this.state);
            
            if( response.data.errors === "" ){    
                if (this.state.cd_usuario !== '') {
                    toast.success('Senha editada com sucesso!', { autoClose: 3000, toastId: 1 });
                } else {
                    toast.success('Senha inserida com sucesso!', { autoClose: 3000, toastId: 1 });
                }

            }  else {
                toast.error('Houve um erro, tente novamente mais tarde', { autoClose: 3000, toastId: 1 });
            }
            
        } catch (resp) { 
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }
    
    requiredField = () => {
        return (
            <div className="requiredField">* Campo requerido</div>
        )
    }

    render(){
        const { submittedForm, cd_usuario } = this.state;

        return (
            <div style={{ width:'100%' }}>
                <Sidemenu />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container  m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>Trocar Senha</h2>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            
                                            { cd_usuario !== '' &&
                                                <div className="form-row" >
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="ds_usuario_senha">Senha:</label>
                                                        
                                                        <input 
                                                            type="password" 
                                                            name="ds_usuario_senha" 
                                                            className="form-control"
                                                            value={this.state.ds_usuario_senha}
                                                            onChange={this.handleInputChange} 
                                                        />
                                                        { submittedForm && this.state.ds_usuario_senha === '' && this.requiredField()}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="ds_usuario_senha2">Repita a Senha:</label>
                                                        
                                                        <input 
                                                            type="password" 
                                                            name="ds_usuario_senha2" 
                                                            className="form-control"
                                                            value={this.state.ds_usuario_senha2}
                                                            onChange={this.handleInputChange} 
                                                        />
                                                        { submittedForm && this.state.ds_usuario_senha2 === '' && this.requiredField()}
                                                    </div>
                                                </div>
                                            }
                                            
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                Voltar
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}