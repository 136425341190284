import React, { Component } from "react";

import api from "../../services";

import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import InputMoney from "react-currency-input";
import moment from "moment";
import "moment/locale/pt-br";

import Header from "../../includes/Header";
import Sidemenu from "../../includes/Sidemenu";
import Loading from "../../components/Loading";

toast.configure();

export default class Venda extends Component {
  constructor(props) {
    super(props);
    moment.locale("pt-BR");

    this.state = {
      visibleModal: false,
      isLoading: false,
      popoverOpenImg: false,
      submittedForm: false,
      formError: false,
      tooltip2: "",

      cd_venda: "",
      cd_cliente: "",
      vl_venda: "",
      fl_venda_status: "",
      dt_venda: moment(new Date()).format("DD/MM/YYYY"),
      dt_venda_expiracao: moment(new Date())
        .add("years", 1)
        .format("DD/MM/YYYY"),
      cd_usuario: localStorage.getItem("@guiabasico/cd_usuario"),

      optionsCliente: [],
      displayCliente: "",

      optionsFormapgto: [],
      displayFormapgto: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    event.preventDefault();

    const target = event.target;
    const name = target.name;
    var value2 = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value2,
    });
  }

  componentWillMount() {
    const par = this.props.match.params;

    if (par.id !== undefined) {
      this.setState(
        {
          cd_venda: par.id,
        },
        () => {
          this.consultaAPI();
        }
      );
    } else {
      this.consultaAPI(1);
    }
  }

  consultaAPI = async (act) => {
    /* Função padrão para popular objetos na tela */
    /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
    /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

    try {
      this.setState({ isLoading: true });

      if (act === undefined) {
        var response = await api.get(`/venda/${this.state.cd_venda}`);
      } else {
        var response = null;
      }

      var response2 = await api.get(`/comboCliente`);
      var response3 = await api.get(`/comboFormapgto`);
      console.log(response3);

      Promise.all([response, response2, response3]).then((values) => {
        this.setState({
          isLoading: false,
        });

        let promisse1 = values[0] || undefined;
        let promisse2 = values[1];
        let promisse3 = values[2];
        /**
         * Segunda Promisse - Combo Tipo usuario
         */
        if (promisse1 !== undefined && promisse1.data !== null) {
          let dados = promisse1.data.data || "";

          this.setState(
            {
              cd_cliente: dados.cd_cliente || "",
              cd_formapgto: dados.cd_formapgto || "",
              vl_venda: dados.vl_venda || "",
              fl_venda_status: dados.fl_venda_status || "",
              dt_venda: dados.dt_venda || "",
              dt_venda_expiracao:
                moment(new Date(dados.dt_venda_expiracao)).format(
                  "DD/MM/YYYY"
                ) || "",
            },
            () => {
              console.log("state ", this.state);
            }
          );
        }
        /**
         * Segunda Promisse - Combo Cliente
         */
        if (promisse2 !== undefined && promisse2.data !== null) {
          promisse2.data.data.unshift({
            id: "",
            title: "Selecione...",
          });

          const dados = promisse2.data.data;

          this.setState(
            {
              optionsCliente: dados,
            },
            () => {
              /* Display Cliente */
              this.state.optionsCliente.map((item, i) => {
                if (item.id == this.state.cd_cliente)
                  return this.setState({ displayCliente: item.title });
              });
            }
          );
        }
        /**
         * Terceira Promisse - Combo Formapgto
         */
        if (promisse3 !== undefined && promisse3.data !== null) {
          promisse3.data.data.unshift({
            id: "",
            title: "Selecione...",
          });

          const dados = promisse3.data.data;

          this.setState(
            {
              optionsFormapgto: dados,
            },
            () => {
              /* Display Formapgto */
              this.state.optionsFormapgto.map((item, i) => {
                if (item.id == this.state.cd_formapgto)
                  return this.setState({ displayFormapgto: item.title });
              });
            }
          );
        }
      });
    } catch (response) {
      console.log(response);
      this.setState({ isLoading: false, tooltip2: "Houve um erro" });
    }
  };

  validation = () => {
    const { dt_venda, dt_venda_expiracao, vl_venda, cd_cliente } = this.state;

    if (
      dt_venda === "" ||
      dt_venda_expiracao === "" ||
      vl_venda === "" ||
      cd_cliente === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Função padrão para envio de Formulário
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      submittedForm: true,
    });

    if (!this.validation()) {
      toast.error('Venda não editada. Erro no formulário!', {
        autoClose: 3000,
        toastId: 1,
      });
      this.clearSubmit();
      return false;
    }
    /* Função padrão para popular objetos na tela */
    /* Todas as consultas são feitas de uma vez e depois os dados são manipulados */
    /* Para toda consulta em API usar Try Catch para evitar Crashs no APP */

    try {
      console.log(this.state);
      if (this.state.cd_venda != "") {
        var response = await api.put(
          `/venda/${this.state.cd_venda}`,
          this.state
        );
      } else {
        var response = await api.post(`/venda/`, this.state);
      }

      console.log(response);

      if (response.data.errors === "") {
        if (this.state.cd_venda != "") {
          toast.success("Venda editada com sucesso!", {
            autoClose: 3000,
            toastId: 1,
          });
        } else {
          toast.success("Venda realizada com sucesso!", {
            autoClose: 3000,
            toastId: 1,
          });
        }
        this.clearSubmit();
        this.voltar();
      } else {
        toast.error(response.data.errors, {
          autoClose: 3000,
          toastId: 1,
        });
        this.clearSubmit();
      }
    } catch (resp) {
      console.log(resp);
      this.setState({
        isLoading: false,
        tooltip2: "Houve um erro",
      });
    }
  };

  clearSubmit = () => {
    setTimeout(() => {
      this.setState({
        submittedForm: false,
      });
    }, 2000);
  };

  requiredField = () => {
    return <div className="requiredField">* Campo requerido</div>;
  };

  voltar = () => {
    this.props.history.push("/venda");
  };

  render() {
    const { submittedForm, isLoading } = this.state;

    return (
      <div style={{ width: "100%" }}>
        <Sidemenu />
        <Loading isLoading={isLoading} />
        <main className="admin-main">
          <Header />
          <div className="bg-gray-400">
            <div className="container  m-b-30">
              <div className="row">
                <div className="col-12 p-t-20 p-b-70">
                  <div className="media">
                    <div className="avatar avatar mr-3">
                      <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                    </div>
                    <div className="media-body">
                      <h2>
                        {this.state.cd_venda === "" ? "Nova Venda" : "Venda"}
                      </h2>
                    </div>

                    <div className="float-right">
                      <Link
                        to={`/venda/form/`}
                        className="btn-primary btn btn-md m-b-15 ml-2 mr-2"
                      >
                        <i className="mdi mdi-plus"></i> Nova Venda
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container  pull-up">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={this.handleSubmit}
                      className="formGeral m-t-30"
                    >
                      <div className="form-row">
                        <div className="form-group col-md-3">
                          <label htmlFor="dt_venda">Data da Venda:</label>

                          <InputMask
                            mask="99/99/9999"
                            name="dt_venda"
                            className="form-control"
                            value={this.state.dt_venda}
                            onChange={this.handleInputChange}
                          />
                          {submittedForm &&
                            this.state.dt_venda === "" &&
                            this.requiredField()}
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="dt_venda_expiracao">
                            Data da Expiração:
                          </label>

                          <InputMask
                            mask="99/99/9999"
                            name="dt_venda_expiracao"
                            className="form-control"
                            value={this.state.dt_venda_expiracao}
                            onChange={this.handleInputChange}
                          />
                          {submittedForm &&
                            this.state.dt_venda_expiracao === "" &&
                            this.requiredField()}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="cd_cliente">Cliente:</label>
                          <select
                            name="cd_cliente"
                            placeholder={
                              this.state.displayCliente || "Selecione..."
                            }
                            value={this.state.cd_cliente}
                            onChange={this.handleInputChange}
                            className="form-control"
                          >
                            {this.state.optionsCliente.map((item) => {
                              return (
                                <option value={item.id}>{item.title}</option>
                              );
                            })}
                          </select>
                          {submittedForm &&
                            this.state.cd_cliente === "" &&
                            this.requiredField()}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="cd_formapgto">Forma Pagamento:</label>
                          <select
                            name="cd_formapgto"
                            placeholder={
                              this.state.displayFormapgto || "Selecione..."
                            }
                            value={this.state.cd_formapgto}
                            onChange={this.handleInputChange}
                            className="form-control"
                          >
                            {this.state.optionsFormapgto.map((item) => {
                              return (
                                <option value={item.id}>{item.title}</option>
                              );
                            })}
                          </select>
                          {submittedForm &&
                            this.state.cd_formapgto === "" &&
                            this.requiredField()}
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="vl_venda">Valor:</label>

                          <InputMoney
                            name="vl_venda"
                            className="form-control"
                            decimalSeparator=","
                            thousandSeparator="."
                            value={this.state.vl_venda}
                            onChangeEvent={this.handleInputChange}
                          />

                          {submittedForm &&
                            this.state.vl_venda === "" &&
                            this.requiredField()}
                        </div>

                      <div className="form-group col-md-3">
                          <label htmlFor="fl_venda_status">Acesso ao App/Web:</label>
                          <select
                            name="fl_venda_status"
                            placeholder={
                              this.state.fl_venda_status || "Selecione..."
                            }
                            value={this.state.fl_venda_status}
                            onChange={this.handleInputChange}
                            className="form-control"
                          >
                            <option selected={this.state.fl_venda_status == 1} value="1">Liberado</option>
                            <option selected={this.state.fl_venda_status != 1} value="">Bloqueado</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group float-left">
                        <div
                          onClick={this.voltar}
                          className="btn-outline-primary btn"
                          style={{ cursor: "pointer" }}
                        >
                          Voltar
                        </div>
                      </div>
                      <div className="form-group float-right">
                        {!submittedForm && (
                          <button type="submit" className="btn btn-primary">
                            Salvar
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
